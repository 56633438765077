@import "~assets/css/variable.scss";

.custom-checkbox-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  /* Hide the browser's default checkbox */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    overflow: "hidden";
    min-width: 16px;
    margin-right: 10px;
    border: 1px solid $grey-5;

    /* Create the checkmark/indicator (hidden when not checked) */
    &::after {
      content: "";
      display: none;
    }

    /* Style the checkmark/indicator */
    &::after {
      width: 5px;
      height: 8.5px;
      border: solid $primary-color;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  /* Show the checkmark when checked */
  input:checked ~ .checkmark:after {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -3px 0 0 -0.5px;
  }
}
