@import "~assets/css/variable.scss";

.radio-button-wrapper {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;
  line-height: 40px;
  font-weight: $font-regular;
  color: $black-color;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  input :checked ~ .checkmark {
    background-color: $yellow-sea;
  }
  &:hover input ~ .checkmark {
    background-color: $yellow-sea-hover;
  }
  .checkmark:after {
    top: 6px;
    left: 6px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $white-color;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $yellow-sea;
    border-radius: 50%;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}

.radio-button-wrapper input:checked ~ .checkmark:after {
  display: block;
}
