$white-color: #ffffff;
$black-color: #111111;

$primary-color: #3f6da7;
$secondary-color: #374b6a;
$immozy-dark: #303847;
$immozy-grey-blue: #4e5d75;
$immozy-lightblue: #a0acce;
$hover-red: #1e477a;
$gray: #8b8b8b;
$grey-dark: #262626;
$grey-light: #4f596c;
$medium-gray: #999999;
$light-gray: #d8d8d8;
$muted-gray: #cecece;
$grey-for-button: #030303;
$grey-7: #8c8c8c;
$grey-6: #e5e5e5;
$grey-5: #d9d9d9;
$grey-4: #ccc;
$grey-border: #b4b8be;
$border-grey: #b4b8be;
$bg-white: #f6f6f6;

$yellow: #edbe59;
$green: #38cc6b;

$transparent-green: #38cc6b1a;
$tangerine: #f38300;
$bunting: #2c344e;
$dark-gray: #aeaeae;
$light-red: #f2f2f2;
$yellow-sea: #ffb000;
$yellow-sea-hover: #ffae00b6;
$red: #de2e2e;
$purple: #712ede;

$font: 'Battambang', sans-serif;

$full-width: 100%;
$full-height: 100%;
$full-viewport-width: 100vw;
$full-viewport-height: 100vh;

//Font Weight Variables
$font-light: 300;
$font-regular: 400;
$font-bold: 700;

$btn-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0);
$btn-box-shadow-hover: 0px 0px 4px 1px rgba(0, 0, 0, 0.12);
