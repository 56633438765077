.user-property {
	.property-search-div {
		background-color: $bunting;
		height: 38px;
		padding-right: 20px;
		label {
			font-size: 10px;
			line-height: 14px;
			color: $white-color;
			margin-right: 15px;
		}
		.search-bar {
			width: 162px;
			height: 28px;
			border-radius: 3px;
			border-width: 1px;
			background-color: $white-color;
			padding: 4px 4px 4px 15px;
			font-size: 10px;
			&:focus-visible {
				outline: none;
			}
			&::placeholder {
				color: #adadad;
			}
		}
	}
	.property-filter-section {
		padding: 0 60px;
		.object-number {
			font-size: 18px;
			font-weight: $font-bold;
			line-height: 24px;
			color: $bunting;
			text-align: center;
			margin: 16px 0;
		}
		.filter-button-div {
			margin-bottom: 20px;
			display: grid;
			grid-template-columns: auto auto auto;
			.user-property-sort-wrapper {
				max-width: 180px;

				.sort-div {
					padding: 0;
					justify-content: space-between;
					.sort-container {
						padding: 6px 0 6px 14px;
						.sort-menu-div {
							width: 110px;
						}
					}
					.arrow-outer {
						padding: 6px 14px 6px 0;
					}
				}
			}
			.filter-button {
				width: 121px;
				height: 30px;
				border-radius: 5px;
				border: 1px solid #cdcdcd;
				background-color: transparent;
				font-size: 11px;
				font-weight: $font-bold;
				line-height: 18px;
				color: $bunting;
				margin: 0 3px;
			}
			.check-box-filter-div {
				width: 100%;
				min-width: 175px;
				border-radius: 12px;
				border: 0.6px solid #c8c8c8;
				box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
				top: 50px;
				padding: 15px;
				.filter-menu {
					.check-box-filter {
						.custom-checkbox-container {
							.checkmark {
								margin-right: 10px;
							}
						}
					}
					.user-property-checkbox {
						font-size: 12px;
						line-height: 14px;
						color: $bunting;
						.custom-checkbox-container input:checked ~ .checkmark:after {
							margin: 0;
						}
						.custom-checkbox-container input:checked ~ .checkmark:after {
							width: 10px;
							height: 10px;
							border: none;
							background-color: $tangerine;
							transform: none;
						}
					}
					.active-check-box {
						color: $tangerine;
					}
				}
			}
			.filter-menu {
				left: -9px;
			}
			.color-yellow {
				color: $white-color;
				background-color: $tangerine;
			}
			.reset-filter {
				line-height: 20px;
				&:hover {
					color: $yellow-sea;
				}
			}
			.advertising-btn-wrapper {
				border: 0.5px solid $dark-gray;
				background-color: $bunting;
				border-radius: 5px;
				font-size: 14px;
				width: 121px;
				height: 30px;
				font-weight: $font-bold;
				margin: 0 20px;
				color: $white-color;
			}
		}
	}
	.user-property-card {
		padding: 10px 20px 0 10px;
		min-height: calc(100vh - 100px);
		.add-property-div {
			p {
				font-size: 16px;
				line-height: 25px;
				font-weight: $font-bold;
				color: $bunting;
			}
		}
		.property-add-div {
			width: 453px;
			height: 217px;
			border-radius: 8px;
			border: 0.6px solid #b2b2b2;
		}
		.user-property-card-div {
			width: 25%;
			.column-wrapper {
				width: 100%;
			}

			.property-button-div {
				top: 85px;
				right: -5px;
				z-index: 10;
				.property-button {
					margin-bottom: 10px;
					.none-icon {
						fill: #d0d0d0;
					}
				}
			}
			.inactive-property {
				width: 100%;
				height: 100%;
				top: 0;
				z-index: 9;
				opacity: 0.5;
				background-color: $white-color;
			}
		}
	}
}
.waring-popup-wrapper {
	padding: 10px 40px 20px;
	width: 310px;
	.immoweb-icon {
		width: 170px;
		height: 45px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.waring-popup-text {
		margin: 20px 0;
		h6 {
			font-size: 14px;
			line-height: 16px;
			color: $bunting;
			margin-bottom: 15px;
		}
		p {
			font-size: 12px;
			line-height: 14px;
			color: $bunting;
		}
	}
	.confirm-button {
		font-size: 14px;
		width: 87px;
		line-height: 26px;
		padding: 0;
		margin-bottom: 15px;
	}
	.cancel-button {
		font-size: 12px;
		line-height: 23px;
		color: $bunting;
		font-weight: $font-bold;
	}
}
