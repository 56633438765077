@import '~assets/css/variable.scss';

select {
	outline: none;
}

option,
option:focus {
	outline: none !important;
}

.search-img {
	height: 130px;
	object-fit: cover;
}

.search-img-single {
	height: 83%;
	object-fit: cover;
	width: 100%;
	border-top-left-radius: 19px;
	border-top-right-radius: 19px;
}

.carousel-wrapper .image-gallery,
.carousel-wrapper .image-gallery-content,
.carousel-wrapper .image-gallery-slide-wrapper,
.carousel-wrapper .image-gallery-swipe,
.carousel-wrapper .image-gallery-slides,
.carousel-wrapper .image-gallery-slide,
.carousel-wrapper .image-gallery-slide div {
	height: $full-height;
}

.search-text {
	font-size: 20px;
	color: #545353;
}

.search-item-title {
	color: #545353;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 14px;
	font-weight: 600;

	span {
		width: 81%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.fa-heart-o:hover {
	color: $primary-color;
}

.favo-icon {
	color: $primary-color;
}

.btn-dark:hover {
	background-color: $secondary-color;
	border-color: $secondary-color;
}

.image-gallery-icon {
	filter: none !important;
	will-change: scroll, opacity, transform;

	.image-gallery-svg {
		box-shadow: $btn-box-shadow-hover;
		transition: all 0.3s ease;
	}
}
.map-img-gallary {
	.image-gallery-image {
		height: 175px !important;
		width: 100% !important;
		object-fit: cover !important;
	}

	.image-gallery-left-nav,
	.image-gallery-right-nav {
		.image-gallery-svg {
			height: 25px;
			color: $black-color;
			width: 25px;
			background-color: $white-color;
			border-radius: 50%;
			transition: all 0.4s ease;

			&:hover {
				color: $primary-color !important;
			}
		}
	}
}

.search-img1 {
	height: 83%;

	.image-gallery-content .image-gallery-slide .image-gallery-image {
		object-fit: cover;
		height: $full-height;
		background-color: #f5f5f5;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
	}
	.image-gallery-left-nav,
	.image-gallery-right-nav {
		z-index: 1;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.5s ease;
		padding: 0 10px;

		.image-gallery-svg {
			height: 33px;
			color: $black-color;
			width: 33px;
			background-color: $white-color;
			border-radius: 50%;
		}
	}
}

.property-card:hover .search-img1 .image-gallery-left-nav,
.property-card:hover .search-img1 .image-gallery-right-nav {
	opacity: 1;
	pointer-events: all;
}

.price-div,
.price-div-de {
	font-size: 21px;
	float: right;
	text-align: end;
	margin: 10px;
}

.infowin-container {
	position: relative;
	overflow: hidden;
	border-radius: 14px;
	animation: fadein 0.5s;

	.like-icon,
	.like-icon-multi {
		z-index: 1002;
		height: 25px;
		width: 25px;
		text-align: center;
		right: 10px;

		i {
			font-size: 15px;
			line-height: 27px;
			width: 25px;
			right: 10px;

			&:hover {
				color: $primary-color !important;
				font-size: 15px !important;
				font-weight: $font-bold;
				line-height: 27px !important;
			}
		}
	}

	.card-img {
		height: 175px;
		width: 282px;

		.single-img {
			width: $full-width;
			height: $full-height;
			object-fit: cover;
		}

		.image-gallery {
			background-color: $white-color;
			border-left: 1px solid #a4a4a4;
			border-right: 1px solid #a4a4a4;
		}

		.image-gallery-image {
			border-top-left-radius: 12px;
			border-top-right-radius: 12px;
		}
	}

	@keyframes fadein {
		from {
			opacity: 0;
		}
		to {
			opacity: 1;
		}
	}

	.map-card-nav {
		background-color: $white-color;
		max-width: 130px;
		margin: 0 auto;
		padding-top: 5px;
		border-bottom-left-radius: 14px;
		border-bottom-right-radius: 14px;
		box-shadow: inset 0 4px 8px 0px rgba(0, 0, 0, 0.1);

		.index-label {
			height: 31px;
			width: 31px;
			background-color: $white-color;
			border: 2px solid $immozy-dark;
			border-radius: 31px;
			text-align: center;
			font-size: 20px;
			margin-top: 2px;
			line-height: 28px;
		}
	}

	.map-label {
		background-color: $white-color;
		border-bottom-left-radius: 14px;
		border-bottom-right-radius: 14px;
		padding: 10px 5px;

		&.box-shadow {
			box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.23);
		}
	}
}

.pagination-div {
	height: 50px;
	background: white;
	border: 1px solid #a4a4a4;
	border-bottom: none;
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
	font-family: 'BrandonText' !important;

	.multi-label {
		font-size: 16px;
		color: $grey-light;
		text-align: center;
		letter-spacing: 0.06em;
		line-height: 18px;
	}

	.page-no-label {
		font-size: 12px;
		color: $grey-light;
		text-align: center;
		letter-spacing: 0.06em;
		line-height: 18px;
		font-weight: $font-bold;
	}

	.image-gallery-icon .image-gallery-svg {
		box-shadow: $btn-box-shadow;
		border: 1px solid rgba(180, 184, 190, 1) !important;

		&:hover {
			box-shadow: $btn-box-shadow-hover !important;
		}
	}
}

.fa-map-marker {
	font-size: 10px;
	margin-right: 3px;
}

.rate-box {
	position: absolute;
	min-width: 130px;
	left: -140px;
	top: 0;
	min-height: 60px;
	padding: 10px;
	background-color: $white-color;
	box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);
	border-radius: 4px;

	label {
		font-size: 15px;
	}

	button {
		position: absolute;
		top: 2px;
		right: 4px;
		width: 12px;
		height: 12px;
		line-height: 12px;
	}
}

.rate-info span {
	font-weight: 600;
	font-size: 15px;
}

.map-filter {
	align-items: center;
	font-size: 12px;
	color: $immozy-dark;
	letter-spacing: 0.04em;
	margin-left: 5px;
	padding-right: 10px;

	input[type='checkbox'] {
		height: 20px;
		margin: 0px;
	}
}

.highlight {
	background: #ececec;
}

.rate-close-map {
	font-size: 13px;
	position: absolute;
	top: 4px;
	right: 4px;
	width: 12px;
	height: 12px;
	line-height: 12px;
}

.a {
	z-index: 1006;
	position: absolute;
}

.b {
	margin: 10px;
}

.MuiGrid-align-items-xs-center {
	align-items: center;
}

.MuiGrid-item {
	margin: 0;
	box-sizing: border-box;
}

.MuiFormControlLabel-root {
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	margin-left: -11px;
	margin-right: 16px;
	vertical-align: middle;
	-webkit-tap-highlight-color: transparent;
	text-transform: capitalize;
}

.e {
	background-color: $white-color;
	box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
	// padding: 5px;
	margin: 0px;
}

.gm-svpc,
.gm-style-mtc,
.gm-style-mtc {
	display: none;
}
.select {
	border-radius: 3px;
}

.infoBox {
	width: 282px !important;
	border-radius: 14px;
	z-index: 200 !important;
	box-shadow: 0 0 10px -1px grey !important;
	transition: box-shadow 0.3s ease;
	animation: fadein 1s;

	&:hover {
		box-shadow: 0 3px 20px -1px grey !important;
	}
}

.location-map {
	font-size: 12px;
	padding: 4px 0px 0px 0px;
	max-height: 20px;
	overflow: hidden;
	color: #a9a2a2;
}

.ob .input-group {
	position: relative;
	display: flex;
	align-items: stretch;
	width: $full-width;
	flex-wrap: nowrap;
}

.prev-class {
	display: none;
}

.rounded-div select {
	padding: 0.25rem 0.5rem;
}

.sort-div-wrapper {
	width: 100%;
	max-width: 121px;
	// max-width: 153px;
	.sort-div {
		cursor: pointer;
		border-radius: 5px;
		width: 100%;
		border: 1px solid #cdcdcd;
		display: inline-flex;
		box-shadow: $btn-box-shadow;
		transition: all 0.3s ease;
		padding-right: 3px;
		.active {
			color: $primary-color;
			border-color: $primary-color;
		}

		&:hover {
			box-shadow: $btn-box-shadow-hover;
		}
	}
}

.asc-active {
	color: $secondary-color;
}

.sort-list {
	background-color: $white-color;
	border-radius: 20px;
	position: absolute;
	z-index: 999;
	padding: 15px 0px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 3px 2px;
	font-size: 11px;
	color: $immozy-dark;
	letter-spacing: 0.06em;
	line-height: 32px;
	width: 200px;
	transition: all 0.8s ease-in-out;

	&::before {
		content: '';
		position: absolute;
		top: -15px;
		left: 30px;
		border-bottom: 20px solid $white-color;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
	}

	li {
		padding: 1px 0px 0px 35px;
		text-align: left;
	}

	.active {
		color: $primary-color;
	}

	li:hover {
		background-color: $bg-white;
	}
}

.arrow-div-desc {
	background-image: url('../../assets/images/Sort-Icon-Up.png');
	height: 11px;
	width: 14px;
}

.arrow-div-asc {
	background-image: url('../../assets/images/Sort-Icon-Up.png');
	transform: rotate(180deg);
	height: 11px;
	width: 14px;
}

.arrow-outer {
	border: none;
	padding-left: 13px;
}

.sort-container {
	display: flex;
	align-items: center;
	padding: 5px 0 5px 20px;

	.sort-menu-div {
		font-size: 11px;
		padding: 0 15px 0 0;
		letter-spacing: 0.06em;
		font-weight: $font-bold;
		width: 50px;
		overflow: hidden;
		white-space: nowrap;
		display: block;
		text-overflow: ellipsis;
		border: none;
		&:focus-visible {
			outline: none;
		}
	}

	i {
		color: $yellow-sea;
	}
}

.margin-top-sort {
	top: 100px !important;
}

.sort-drop {
	font-size: 15px;
}

.disable-div {
	opacity: 0.4 !important;
}

.margin20 {
	margin-bottom: 20px;
	margin-top: 20px;
}

.image-gallery-thumbnail-image {
	height: 75px !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
	max-height: calc(100vh - 65px);
}

.fullscreen {
	.image-gallery-content {
		.image-gallery-slide {
			.image-gallery-image {
				margin-top: 50px;
				min-height: 85vh;
			}
		}
	}
}

.single-floor-image {
	max-height: 40vh;
	max-width: 80%;
}

.image-gallery-content.fullscreen {
	margin-top: 30px;
}

.carousel {
	.image-gallery {
		width: 100%;

		img {
			height: 420px;
			object-fit: cover;
		}
	}

	.image-gallery-bullets {
		bottom: -25px !important;

		.image-gallery-bullet {
			border: none !important;
			background: rgb(160, 155, 155) !important;
			box-shadow: none !important;

			&.active {
				background: var(--app-primary-color) !important;
			}
		}
	}

	.image-gallery-left-nav,
	.image-gallery-right-nav {
		.image-gallery-svg {
			height: 35px !important;
			color: #a4a4a4;
			width: 35px !important;
			background-color: white;
			border-radius: 50% !important;
			box-shadow: none !important;
		}
	}
}

.image-gallery-fullscreen-button {
	bottom: -20px !important;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
	.image-gallery-svg {
		height: 32px !important;
		width: 32px !important;
	}
}

@media only screen and (min-width: 2560px) {
	.property-card {
		height: 336px;
		width: 560px;
	}

	.notices {
		width: 25% !important;
	}
}

.adr-label {
	margin: 12px 0 5px;
	font-size: 15px;
	color: $grey-light;
}

.location {
	li {
		&:nth-child(2) {
			font-size: 13px;
			color: $border-grey;
			text-align: center;
			letter-spacing: 0.05em;
			line-height: 13px;
			text-align: inherit;
		}
	}
}

.map-label-price {
	font-size: 17px;
	color: $immozy-grey-blue;
	text-align: right;
	line-height: 25px;
	font-weight: $font-bold;
}

.sub-label-map {
	color: $border-grey;
	text-align: center;
	letter-spacing: 0.05em;
	line-height: 14px;
	text-align: left;
	font-size: 12px;
}

.map-label-adr {
	font-size: 14px;
	color: $immozy-grey-blue;
	letter-spacing: 0.05em;
	line-height: 20px;
	width: 60%;
}

.property-info {
	margin-top: 15px;
}

.map-price-container {
	color: $red !important;
}

.ad-img-link {
	width: 45%;
	display: inline-block;
}

.gm-svpc,
.gm-style-mtc,
.gm-style-mtc {
	display: none;
}
