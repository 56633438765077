@media only screen and (min-width: 2560px) {
	.loading-background {
		.loading-bar1 {
			top: 300px;
		}

		.loader-text-1 {
			top: 500px;
		}

		.loader-text-2 {
			top: 560px;
		}

		.loader-text-3 {
			top: 700px;
		}
	}
}

@media only screen and (min-width: 1920px) {
	.search-result-section {
		.map-wrapper {
			overflow: hidden;
			transition: all 0.3s ease;

			&.sticky {
				right: var(--element-window-width);
				transition: all 0.3s ease;
			}

			&.sticky-footer {
				right: 0;
			}
		}
	}

	.full-screen-btn-fixed {
		&.full-screen-btn-fixed {
			right: calc(var(--element-window-width) + 7px);
		}
	}
}

@media only screen and (min-width: 1680px) {
	.image-gallery-popup {
		&.one-image-slider {
			.image-gallery-image {
				height: calc(100vh - 235px) !important;
			}
		}
	}
}

@media only screen and (max-width: 1600px) {
	.pagination-width {
		max-width: unset;
	}

	.property-result-wrapper {
		max-width: auto;
	}

	.search-result-section .map-wrapper {
		width: 71%;

		.custom-filters {
			&.hide-filters {
				right: -670px;
			}
		}

		.map-filter {
			font-size: 11px;
		}
	}

	.map-wrapper .custom-filters {
		width: 725px;
	}

	.welcome-screen {
		background-size: 110%;
	}
}

@media only screen and (max-width: 1600px) {
	.more-filter-height {
		height: 470px !important;
	}
}

@media only screen and (max-width: 1440px) {
	.property-slider {
		.slick-list {
			width: 90% !important;
			margin: 0 auto;
		}
	}

	.property-card-wrapper {
		.property-card-responsive {
			width: 33.33%;
		}
	}
	.user-property {
		.user-property-card {
			.user-property-card-div {
				width: 33.33%;
			}
		}
	}
	.pagination {
		padding-left: 10px;
		padding-right: 5px;

		li {
			margin-right: 6px !important;
		}
	}
	.property-result-wrapper {
		.top-search-section {
			.top-search-wrapper {
				height: 45px;
				// margin: 0 10px;
			}
			.top-search-title {
				font-size: 8px;
			}
		}
		.contact-frame {
			height: 8px;
		}
	}
	.reset-filter {
		font-size: 8px;
	}
}

@media only screen and (max-width: 1366px) {
	.section-heading {
		padding: 10px 0px 15px !important;

		.title {
			font-size: 24px !important;
		}
	}

	.pagination {
		li {
			margin-right: 3px !important;
		}

		a {
			font-size: 16px !important;
		}
	}

	.internet-speed {
		margin-bottom: 20px !important;
	}

	.loading-background {
		.loading-bar1 {
			top: 150px;
		}

		.loader-text-1 {
			top: 280px;
		}

		.loader-text-2 {
			top: 330px;
		}

		.loader-text-3 {
			top: 430px;
		}
	}
}

@media only screen and (max-width: 1280px) {
	.property-card {
		flex-direction: column;
		align-items: center;

		.carousel-wrapper {
			margin-bottom: 20px;
		}
	}

	.curved-div {
		.button-wrapper {
			padding: 0 25px;
		}
	}
}

@media only screen and (max-width: 1210px) {
	.logo-img {
		margin-left: 20px;
		margin-right: 20px;
	}

	.res-nav {
		justify-content: initial !important;
	}

	.curved-div {
		.button-wrapper {
			.button-filter,
			.button-apply {
				width: auto !important;
				font-size: 11px;
				padding: 0 15px;
			}
		}
	}
	.main-footer {
		.footer-logo-div {
			width: 50%;
		}
		.footer-link-wrapper {
			width: 50%;
		}
	}
}
@media only screen and (max-width: 1100px) {
	.our-team-wrapper {
		.team-about-us-section {
			.card-wrapper {
				grid-template-columns: auto auto;
			}
		}
		.features-section {
			.features {
				gap: 15px;
			}
		}
		.partner-models-section {
			.broker-and-franchise {
				.discretion-wrapper {
					gap: 15px;
				}
			}
		}
	}
	.location-wrapper {
		.city-name-div {
			column-gap: 50px;
			.city-name {
				column-gap: 50px;
			}
		}
	}
}

@media only screen and (max-width: 1050px) {
	.search-result-section {
		.map-wrapper {
			width: 67%;
		}
	}

	.property-card-wrapper {
		padding: 0 10px 0px 0 !important;
	}

	.map-wrapper {
		.custom-filters {
			width: 665px !important;

			&.hide-filters {
				right: -605px !important;

				.map-filter {
					margin-left: 20px;
				}
			}

			.map-filter {
				padding-right: 0;

				.ml-2 {
					margin-left: 0 !important;
				}
			}

			.map-icon {
				width: 19px;

				&.park-icon {
					width: 15px;
				}

				&.transport-icon {
					width: 12px;
				}

				&.supermarket-icon {
					width: 16px;
				}

				&.medical-icon {
					width: 17px;
				}
			}

			img {
				&.pointer {
					width: 35px;
					height: 35px;
				}
			}
		}
	}
}

@media only screen and (max-width: 1023px) {
	.property-card-wrapper {
		.property-card-responsive {
			width: 50%;
		}
	}
	.user-property {
		.user-property-card {
			.user-property-card-div {
				width: 50%;
			}
		}
	}
	.about-us-wrapper {
		.about-us-text {
			padding: 28px 10px;
		}
	}
}

@media only screen and (max-width: 992px) {
	.curved-div {
		.button-wrapper {
			padding: 0;

			.button-filter,
			.button-apply {
				width: auto !important;
				font-size: 11px;
				padding: 0 15px;
			}
		}
	}
	.property-result-wrapper {
		.top-search-section {
			.top-search-wrapper {
				.top-search-title {
					line-height: 20px;
					font-size: 10px;
				}
				.advertising-btn-wrapper {
					margin: 0 10px;
				}
			}
		}
		.user-location-wrapper {
			width: 200px;
		}
	}
	.home-header {
		.navlink-data {
			line-height: 20px;
			font-size: 12px;
		}
	}
	.section-width {
		padding: 0 10px;
	}
	.energy-certificate-section {
		.certificate-needed-section {
			.certificate-cards {
				flex-direction: column;
				align-items: center;
				.certificate-needed-card {
					margin-top: 16px;
				}
			}
		}
	}
	.price-advice-section {
		.advised-section {
			padding: 0 10px;
		}
	}
	.our-team-wrapper {
		.features-section {
			.features {
				grid-template-columns: auto auto;
				.feature-div {
					h3 {
						font-size: 18px;
					}
				}
			}
		}
	}
	.description-section {
		margin: 40px auto;
	}
	.location-wrapper {
		.city-name-div {
			column-gap: 30px;
			.city-name {
				column-gap: 30px;
			}
		}
	}
}

@media only screen and (max-width: 840px) {
	.property-card-wrapper {
		.column-wrapper {
			width: 100%;

			.property-card {
				max-width: 440px;
				margin: 10px auto;
			}
		}
	}
	.certificate-card-wrapper {
		display: flex;
		align-items: center;
		flex-direction: column;
		.certificate-needed-card {
			row-gap: 20px;
			width: 400px;
			flex-direction: column-reverse;
			.card-image {
				width: 100%;
			}
			.card-description {
				width: 100%;
			}
		}
		&:last-child {
			.card-image {
				order: 2;
			}
		}
	}
}

@media only screen and (max-width: 800px) {
	.object-detail {
		.model-wrapper {
			.image-gallery-popup {
				.contact-popup-wrapper {
					max-width: 510px;
				}
			}
		}
	}
	.description-section {
		padding: 0 10px;
	}
	.register-section {
		padding: 20px 10px;
	}
	.title-image-wrapper {
		height: 295px;
	}
	.common-page-tittle-div {
		max-width: 300px;
		height: 85px;
		h1 {
			font-size: 25px;
			line-height: 27px;
		}
	}

	.energy-certificate-section {
		.certificate-needed-section {
			.certificate-cards {
				.certificate-needed-card {
					height: auto;
				}
			}
		}
	}

	.price-advice-section {
		.view-of-property-section {
			.property-view {
				margin: 0;
				gap: 40px;
			}
		}
	}
	.contact-container {
		.contact-form-container {
			.contact-form-wrapper {
				.contact-form-title {
					padding: 0 10px;
				}
				.contact-form {
					padding: 20px 10px;
				}
			}
		}
	}
	.our-team-wrapper {
		.team-about-us-section {
			.card-wrapper {
				gap: 20px;
				.about-us-card {
					padding: 0 20px 20px;
					max-width: 280px;
				}
			}
		}
	}
	.appraise-section {
		.market-report-section {
			.certificate-cards {
				.certificate-needed-card {
					height: auto;
				}
			}
		}
	}
}
@media only screen and (max-width: 750px) {
	.our-team-wrapper {
		.partner-models-section {
			.broker-and-franchise {
				.discretion-wrapper {
					grid-template-columns: auto;
				}
			}
		}
	}
	.location-wrapper {
		.city-name-div {
			.city-name {
				a {
					font-size: 16px;
					line-height: 32px;
				}
			}
		}
	}
	.main-footer {
		flex-direction: column;
		.footer-logo-div {
			width: 100%;
			text-align: center;
		}
		.footer-link-wrapper {
			width: 100%;
			max-width: unset;
			.footer-link {
				margin-top: 24px;
			}
		}
	}

	.energy-image {
		width: 100%;
	}
}
@media only screen and (max-width: 600px) {
	.title-image-wrapper {
		height: 230px;
	}
	.common-page-tittle-div {
		max-width: 250px;
		height: 80px;
		h1 {
			font-size: 22px;
			line-height: 24px;
		}
	}
	.register-form {
		flex-direction: column;
		.register-email {
			margin-bottom: 10px;
		}
	}

	.price-advice-section {
		.view-of-property-section {
			.property-view {
				flex-direction: column;
				align-items: center;
				gap: 0px;
			}
		}
	}
	.contact-container {
		.contact-form-container {
			padding: 34px 0 0 0;
			.contact-form-wrapper {
				.contact-form {
					form {
						label {
							line-height: 34px;
						}
					}
				}
			}
		}
	}
	.our-team-wrapper {
		.team-about-us-section {
			.card-wrapper {
				grid-template-columns: auto;
				.about-us-card {
					padding: 0 20px 20px;
					max-width: 350px;
				}
			}
		}
		.partner-models-section {
			.broker-and-franchise {
				.title-text-wrapper {
					height: 68px;
				}
			}
		}
	}
	.location-wrapper {
		.location-text {
			.location-tittle {
				font-size: 30px;
				line-height: 45px;
			}
		}
		.city-name-div {
			grid-template-columns: auto;
			.map-wrapper {
				// max-width: unset;
				margin: 0 auto;
			}
			.city-name {
				column-gap: 100px;
			}
		}
	}
}
@media only screen and (max-width: 500px) {
	.contact-container {
		.contact-form-container {
			.contact-form-wrapper {
				.contact-form {
					form {
						.form-check {
							.form-check-label {
								line-height: 26px;
							}
							.checkmark {
								top: 4px;
							}
						}
					}
				}
			}
		}
	}
	.imprint-wrapper {
		.description-section {
			.description-tittle {
				margin-bottom: 20px;
				.description-subtittle {
					margin-bottom: 21px;
				}
				h3,
				p {
					word-break: break-all;
				}
			}
		}
	}
	.our-team-wrapper {
		.partner-models-section {
			.broker-and-franchise {
				.title-text-wrapper {
					height: 58px;
				}
			}
		}
	}
	.public-page-font {
		h1 {
			font-size: 20px;
			line-height: 26px;
		}
		h4 {
			font-size: 16px;
			line-height: 22px;
		}
		h3 {
			font-size: 16px;
			line-height: 22px;
		}
		p {
			font-size: 12px;
			line-height: 18px;
		}
		ol {
			li {
				font-size: 12px;
				line-height: 18px;
			}
		}
	}
	.energy-certificate-section {
		.energy-certificate-div {
			p {
				font-size: 12px;
				line-height: 18px;
			}
			h1 {
				font-size: 20px;
				line-height: 26px;
			}
		}
	}
	.certificate-needed-section {
		margin: 30px auto 40px;
	}
	.about-us-wrapper {
		.about-us-text {
			padding: 28px 10px;
			.text-wrapper {
				h3 {
					font-size: 20px;
					line-height: 26px;
				}
				p {
					font-size: 12px;
					line-height: 18px;
				}
			}
		}
	}
	.help-section {
		.description-section {
			.description-tittle {
				h3 {
					font-size: 20px;
					line-height: 26px;
					margin-top: 15px;
				}
				p {
					font-size: 12px;
					line-height: 18px;
				}
			}
		}
	}
	.description-section {
		.description-tittle {
			p {
				a {
					font-size: 12px;
				}
			}
		}
	}

	.appraise-section {
		.market-report-section {
			.title-text {
				font-size: 20px;
				line-height: 26px;
			}
		}
	}

	.price-advice-section {
		.view-of-property-section {
			h1 {
				font-size: 20px;
				line-height: 26px;
				padding: 30px 0;
			}
			.property-view {
				.property-div {
					height: 430px;
					h3 {
						margin: 15px 0;
					}
				}
			}
		}
	}
	.top-property-object {
		.property-wrapper {
			.home-card {
				display: none !important;
			}
			.home-swiper-card {
				display: flex !important;
			}
		}
	}
	.home-container {
		.Services {
			.service-wrapper {
				display: grid;
				grid-template-columns: auto auto;
			}
			.services-hover-effect {
				padding: 5px;
				width: 100%;
				min-width: 170px;
				.service-icons {
					max-width: 60px;
					height: 60px;
				}
				.title {
					font-size: 18px;
					line-height: 50px;
				}
				.subtitle {
					font-size: 14px;
					line-height: 50px;
				}
			}
		}
		.filter-section {
			display: none;
		}
		.location-tittle {
			font-size: 25px;
			line-height: 40px;
		}
	}
	.home-header {
		.profile-icon {
			display: none;
		}
		.publick-page-links {
			display: none !important;
		}
		.header-icon {
			width: 100%;
		}
	}
	.property-card-wrapper {
		padding: 0 10px 40px !important;
		.column-wrapper {
			.property-card {
				margin: 0px;
			}
		}
	}
	.teaser-wrapper {
		height: 250px;
		.teaser-image-wrapper {
			background-position-x: -145px;
			.teaser-subtitle-wrapper {
				display: none;
			}
			.teaser-title-wrapper {
				.teaser-title {
					font-size: 43px;
					line-height: 50px;
					margin-top: 10px;
				}
			}
		}
	}
	.object-detail {
		.container {
			display: none;
		}
	}
	.mobile-responsive-object {
		display: block !important;
	}
	.object-user-detail-div {
		word-break: break-all;
	}
}
@media only screen and (max-width: 450px) {
	.our-team-wrapper {
		.features-section {
			.features {
				grid-template-columns: auto;
			}
		}
		.cooperation-partner-section {
			.cooperation-partner-image {
				height: 280px;
			}
		}
	}
	.location-wrapper {
		.location-text {
			.location-tittle {
				font-size: 25px;
				line-height: 40px;
			}
			.location-subtittle {
				font-size: 16px;
				line-height: 22px;
			}
		}
	}
	.main-footer {
		.footer-link-wrapper {
			flex-direction: column;
		}
	}

	.certificate-card-wrapper {
		.certificate-needed-card {
			width: 100%;
		}
	}
}
