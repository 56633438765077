.text--primary {
	color: $primary-color;
}

span.disable-links {
	pointer-events: none;
}

.property-card-wrapper {
	&::-webkit-scrollbar {
		width: 10px;
		height: 18px;
		background-color: $white-color;
		border-radius: 7px;
	}

	&::-webkit-scrollbar-thumb {
		height: 6px;
		background-clip: padding-box;
		border-radius: 10px;
		background-color: $white-color;
	}

	&::-webkit-scrollbar-thumb {
		height: 6px;
		background-clip: padding-box;
		border-radius: 10px;
		background-color: $muted-gray;
	}

	&::-webkit-scrollbar-corner {
		background-color: transparent;
	}

	margin-bottom: 15px;
	justify-content: center;
	transition: background 0.4s ease;

	.column-wrapper {
		max-height: 350px;
		position: relative;

		&.column-w-100 {
			width: 100% !important;
		}
	}
	.property-type {
		width: 120px;
		height: 30px;
		border-radius: 8px 8px 0px 0px;
		border: 0.7px solid $white-color;
		background: $bunting;
		top: 254px;
		right: 36%;
		z-index: 9;

		p {
			font-size: 12px;
			line-height: 30px;
			font-weight: $font-bold;
			color: $white-color;
			text-align: center;
			text-transform: uppercase;
		}
	}
	.top-property-type {
		top: 239px;
	}
	.property-sell {
		background-color: $tangerine;
	}
}

.section-pd-top {
	padding-top: 40px;
}

.dropdown-toggle,
.dropdown-toggle:hover {
	color: $white-color;
}

.dropdown-item {
	padding: 10px;
}

.dropdown-item:first-child {
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.dropdown-item:active,
.dropdown-item:focus {
	background-color: $secondary-color;
	color: $white-color !important;
}

.dropdown-item:last-child {
	border-bottom-left-radius: 0.25rem;
	border-bottom-right-radius: 0.25rem;
}

.dropdown-menu {
	padding: 0;
}

.dropdown-divider {
	margin: 0;
}

.btn {
	&:hover {
		color: $white-color !important;
		background-color: $primary-color;
	}

	&.btn-map {
		background-color: $white-color;
		background-repeat: no-repeat;
		background-position-x: 10%;
		background-position-y: 50%;
		width: 109px;
		box-shadow: $btn-box-shadow;
		display: flex;
		align-items: center;

		p {
			font-weight: $font-bold;
			font-size: 11px;
			line-height: 60px;
			padding-left: 10px;
		}
		svg {
			fill: $secondary-color;
			width: 18px;
			height: 22px;
		}

		p {
			line-height: 1;
			// width: calc(100% - 25px);
		}

		&:hover {
			background-color: $white-color !important;
			box-shadow: $btn-box-shadow-hover;
			border-color: $border-grey;
			color: $secondary-color !important;
		}

		&.active {
			background-color: $primary-color;
			color: $white-color;

			svg {
				fill: $white-color;
			}

			&:hover {
				background-color: $hover-red !important;
				color: $white-color !important;
				box-shadow: 0 0 0 0 !important;
			}
		}

		.map-btn-icon {
			margin-left: -32px;
			margin-right: 10px;
		}
	}
}

ul,
li {
	margin: 0px;
	list-style-type: none;
}

textarea {
	&:focus {
		&::-webkit-input-placeholder,
		&:-moz-placeholder,
		&::-moz-placeholder,
		&:-ms-input-placeholder {
			color: transparent;
		}
	}
}

input {
	&::-webkit-input-placeholder,
	&:-moz-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder {
		color: $medium-gray;
	}
}

textarea {
	&::-webkit-input-placeholder,
	&:-moz-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder {
		color: $medium-gray;
	}
}

label {
	display: block;
	margin: 0;
}

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}

/*------------------------------------------------------------------
[ Alert validate ]*/

.form-group {
	position: relative;
}

.fade-in {
	-webkit-animation: fadeIn ease 1s;
	animation: fadeIn ease 1s;
}

.fade-out {
	-webkit-animation: fadeOut ease 1s;
	animation: fadeOut ease 1s;
}

.fade-in-out {
	-webkit-animation: fadeInOut ease 0.8s;
	animation: fadeInOut ease 1s;
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@-webkit-keyframes fadeInOut {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeInOut {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.loading-dots .dot {
	display: inline;
	margin-left: 0.2em;
	margin-right: 0.2em;
	position: relative;
	opacity: 0;
	-webkit-animation: showHideDot 1.5s ease-in-out infinite;
	animation: showHideDot 1.5s ease-in-out infinite;
}

.loading-dots .dot.one {
	-webkit-animation-delay: 0.1s;
	animation-delay: 0.1s;
}

.loading-dots .dot.two {
	-webkit-animation-delay: 0.2s;
	animation-delay: 0.2s;
}

.loading-dots .dot.three {
	-webkit-animation-delay: 0.3s;
	animation-delay: 0.3s;
}

@-webkit-keyframes showHideDot {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	60% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

@keyframes showHideDot {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 1;
	}

	60% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}

.DefaultBackground {
	background: $white-color !important;
	border-top: 5px solid $light-gray !important;
	border-bottom: none !important;
	border-right: none !important;
	border-left: none !important;
}

.DefaultProgressBar_background__horizontal {
	top: -2px !important;
	height: 5px !important;
}

.DefaultProgressBar_progressBar {
	background-color: $primary-color !important;
}

.top-match {
	.handleContainer_1 .DefaultHandle_handle:nth-child(2) {
		pointer-events: none !important;
		border: 1px solid $light-gray !important;

		&::before {
			background-image: url('../../assets/images/slider-handle-disable.png') !important;
			background-repeat: no-repeat;
		}
	}
}

.disabled-bar .DefaultHandle_handle {
	border: 1.5px solid $muted-gray !important;

	&:before {
		background-image: url('../../assets/images/slider-handle-disable.png') !important;
	}
}

.disabled-bar .DefaultProgressBar_progressBar {
	background-color: $muted-gray !important;
}

.profile-head {
	h5 {
		color: #333;
	}

	h6 {
		color: #4b246b;
	}

	.nav-tabs {
		.nav-link.active {
			border: none;
			border-bottom: 2px solid #4b246b;
		}
	}

	.nav-tabs .nav-link {
		font-weight: $font-bold;
		border: none;
	}
}

.add-prop-btn {
	font-size: 14px;
	font-weight: $font-bold;
	line-height: 23px;
	border-radius: 8px;
	width: 420px;
	height: 44px;
	background-color: $yellow-sea;
	color: $white-color;
}

.new-prop {
	.nav-tabs .nav-link {
		font-size: 18px;
	}
}

.profile-tab {
	p {
		font-size: 16px;
	}

	label {
		font-weight: $font-bold;
	}

	input :focus {
		border: 1px solid lightgray;
	}

	input {
		border: 1px solid lightgray;
		font-size: 14px;
		box-shadow: none !important;
		border-radius: 5px;
		height: 54px;
		padding: 5px 20px !important;
		outline: none;
		color: #495057;
	}

	.pwd-icon {
		top: 10px;
	}
}

.summary-title {
	font-size: 18px !important;
	margin: 5px;
	font-weight: $font-bold;
	color: $primary-color;
}

.summary label {
	font-size: 16px;

	span {
		color: $secondary-color;
		margin-right: -10px;
		font-weight: $font-bold;
	}
}

.tab-content {
	font-size: 16px;
}

.p-icon {
	position: absolute;
	top: 10px;
	right: 12px;
	font-size: 18px;
	cursor: pointer;
}

.err-text {
	font-size: 13px;
	color: $red;
	margin-bottom: 10px;
	margin-top: -10px;
}

.toast-body {
	background-color: $white-color;
}

.contactPopup-form-input {
	margin-bottom: 10px;
	width: $full-width;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px 2px;
	border-radius: 5px;
	height: 54px;
	padding: 5px 20px !important;
}

.name-input-profile {
	width: 50%;
	overflow: hidden;
}
.phone-input-profile::-webkit-outer-spin-button,
.phone-input-profile::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
.border-red {
	border-color: $red !important;
}

.remove-photo {
	position: absolute;
	top: 0px;
	left: 89px;
	width: 12px;

	svg {
		fill: $secondary-color;
		background: $white-color;
		padding: 1px;
		border-radius: 50%;

		&:hover {
			fill: $primary-color;
		}
	}
}

.backward,
.forward,
.onboarding2 {
	border: none;
	color: $white-color;
	text-decoration: none;
	transition: background 0.5s ease;
	-moz-transition: background 0.5s ease;
	-webkit-transition: background 0.5s ease;
	-o-transition: background 0.5s ease;
	display: inline-block;
	border-radius: 2px;
	font-size: 18px;
	line-height: 24px;
	cursor: pointer;
	font-weight: $font-bold;
	outline: none;
	background: $secondary-color;
	position: relative;
}

.forward,
.onboarding2 {
	padding: 7px 30px 7px 20px;
}

.btn-success {
	color: $white-color;
	background-color: $secondary-color !important;
	border-color: $secondary-color !important;
}

.error {
	color: $red;
	margin-bottom: 0 !important;
	font-size: 12px;
}

.favorites-animation {
	position: fixed;
	left: var(--element-x-position);
	top: var(--element-y-position);
	z-index: 11;
	-webkit-animation: favoritesAni 0.8s ease-in;
	animation: favoritesAni 0.8s ease-in;

	i {
		color: $primary-color;
		font-size: 22px;
	}
}

@-webkit-keyframes favoritesAni {
	0% {
		left: var(--element-x-position);
		top: var(--element-y-position);
		transform: scale(1);
		z-index: 1111;
	}

	100% {
		left: calc(100% - 170px);
		top: 30px;
		transform: scale(0.3);
		z-index: 1111;
	}
}

@keyframes favoritesAni {
	0% {
		left: var(--element-x-position);
		top: var(--element-y-position);
		transform: scale(1);
		z-index: 1111;
	}

	100% {
		left: calc(100% - 170px);
		top: 30px;
		transform: scale(0.3);
		z-index: 1111;
	}
}

.DefaultHandle_handle {
	width: 30px;
	height: 30px;
	border: 1.5px solid #b5bec5;
	background-color: #fcfcfc;
	border-radius: 100%;
	outline: none;
	z-index: 2;
	cursor: pointer;
	box-shadow: $btn-box-shadow;

	&::after {
		display: none;
	}

	&::before {
		content: '\f0c9';
		font-family: 'FontAwesome';
		-webkit-transform: rotate(90deg);
		-moz-transform: rotate(90deg);
		-ms-transform: rotate(90deg);
		-o-transform: rotate(90deg);
		transform: rotate(90deg);
		height: 100%;
		width: 100%;
		font-size: 17px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #545f74;
		font-weight: normal;
	}

	&:hover,
	&:focus {
		box-shadow: $btn-box-shadow-hover;

		&::before {
			color: $tangerine;
		}
	}
}

.DefaultHandle_handle__horizontal {
	margin-left: -12px;
	top: -12px;
}

.DefaultHandle_handle__vertical {
	margin-top: -12px;
	left: -10px;
}

.DefaultHandle_handle__disabled {
	border-color: #dbdbdb;
}

.DefaultBackground {
	background-color: #fcfcfc;
	height: 15px;
	width: 100%;
	border: 1px solid $light-gray;
	position: relative;
}

.DefaultBackground_background__horizontal {
	height: 15px;
	top: -2px;
	left: -2px;
	bottom: 4px;
	width: 100%;
}

.DefaultBackground_background__vertical {
	width: 15px;
	top: 0px;
	height: 100%;
}

.rheostat {
	position: relative;
	overflow: visible;
}

.medium-modal {
	.title {
		font-size: 16px;
		font-weight: $font-bold;
		text-transform: uppercase;
		margin-bottom: 15px;
		text-align: center;
		color: $grey-light;

		letter-spacing: 0.06em;
		line-height: 18px;
	}

	.desc-dark {
		font-size: 14px;
		color: $secondary-color;
		line-height: 21px;
	}

	.desc-light {
		font-size: 14px;
		color: $grey-light;
		line-height: 21px;
	}
}

.verify-email {
	color: $grey-light;
	padding: 25px 35px;
	width: 584px;
	background-color: $white-color;
	border-radius: 20px;
	box-shadow: 0px 8px 16px 8px rgba(0, 0, 0, 0.14);
	text-align: center;
	margin: 0 auto;

	.title {
		margin-bottom: 15px;
		text-align: center;
	}

	p {
		text-align: left;

		&.list-item {
			font-weight: 600;
			position: relative;
			padding-left: 15px;

			&::before {
				position: absolute;
				content: '-';
				left: 0;
				font-weight: 600;
			}
		}
	}

	.button {
		height: 35px;
		width: 140px;
		margin: 0 auto;
		background-color: $primary-color;
		border-radius: 18px;
		color: $white-color;
		font-size: 12px;
		font-weight: 600;
		transition: all 0.4s ease;

		&:hover {
			background-color: $hover-red;
		}
	}
}
.advance-option {
	height: 0;
	overflow: hidden;
	transition: all 0.4s ease;

	&.show {
		height: 290px;
	}
}

.back-btn {
	.image-gallery-icon .image-gallery-svg {
		border: 1px solid $muted-gray;
		transition: all 0.3s ease;

		&:hover {
			box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0) !important;
		}
	}

	.image-gallery-icon {
		outline: none !important;
	}

	.image-gallery-left-nav,
	.image-gallery-right-nav {
		transition: opacity 0.5s ease;
		padding: 0 20px;
		margin-top: 5px;

		.image-gallery-svg {
			height: 45px;
			color: $black-color;
			width: 45px;
			background-color: $white-color;
			border-radius: 50%;
			outline: none;

			&:active {
				color: $primary-color !important;
			}
		}
	}
}

.more-filter-icon {
	width: 20px !important;
}

.new-style-btn {
	font-size: 13px !important;
	border-radius: 24px !important;
	background: $white-color !important;
	border: 1px solid #caced2 !important;
	box-sizing: border-box;
	box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.02) !important;
	border-radius: 40px;
	color: $black-color !important;
	font-weight: $font-light;
}

#fincredible-button-container {
	z-index: 100;
	position: fixed;
	bottom: 18px;
	right: 100px;
}

.fcb-style-color-default {
	background: $primary-color !important;
}

/* custom-swiper-styles.css */
.swiper-scrollbar {
	--swiper-scrollbar-sides-offset:50px;
	--swiper-scrollbar-size:9px;
}
.swiper-scrollbar-drag {
	background-color: $yellow-sea; /* Change to your desired drag color */
}
