@import '../../assets/css/variable.scss';

.contact-container {
	.contact-form-container {
		padding: 44px 0 0px 0;
		.contact-form-wrapper {
			.contact-form-title {
				width: 100%;
				max-width: 650px;
				margin: 0 auto;
				h4 {
					font-size: 18px;
					line-height: 26px;
					font-weight: $font-regular;
					color: $bunting;
					margin-bottom: 20px;
				}
			}

			.form-tage {
				border: 0.5px solid $dark-gray;
				border-radius: 6px;
				outline: none;
				resize: none;
				font-size: 15px;
				line-height: 40px;
				font-weight: $font-regular;
				color: $bunting;
				height: 37px;
				padding-left: 10px;
			}
			.massage-box {
				height: 131px;
			}
			.error-border {
				border: 0.5px solid red !important;
			}
			.form-tage:focus {
				border: 0.5px solid $dark-gray;
				box-shadow: none;
			}
			.contact-form {
				background-color: $light-red;
				padding: 20px 0;

				form {
					width: 100%;
					max-width: 530px;
					margin: 0 auto;

					margin-bottom: 0px;
					label {
						font-size: 15px;
						line-height: 40px;
						font-weight: $font-regular;
						color: $bunting;
					}

					.form-check {
						padding: 0;
						.form-check-input {
							display: none;
						}

						.checkmark {
							position: absolute;
							top: 8px;
							left: 0;
							height: 19px;
							width: 19px;
							background: $white-color;
							border: 0.5px solid $dark-gray;
							border-radius: 6px;
							&::after {
								content: '';
								position: absolute;
								display: none;
							}
						}

						.form-check-label input:checked ~ .checkmark {
							background-color: #2196f3;
						}
						.form-check-label input:checked ~ .checkmark:after {
							display: block;
						}
						.form-check-label .checkmark:after {
							left: 6px;
							top: 3px;
							width: 5px;
							height: 10px;
							border: solid white;
							border-width: 0 3px 3px 0;
							-webkit-transform: rotate(45deg);
							-ms-transform: rotate(45deg);
							transform: rotate(45deg);
						}
						.form-check-label {
							font-weight: $font-regular;
							font-size: 15px;
							line-height: 40px;
							color: $bunting;
							width: fit-content;
							display: block;
							position: relative;
							padding-left: 35px;
							margin-bottom: 12px;
							cursor: pointer;
							-webkit-user-select: none;
							-moz-user-select: none;
							-ms-user-select: none;
							user-select: none;
							a {
								color: $yellow-sea;
							}
						}
					}
				}
				.btn-primary {
					background-color: $yellow-sea;
					border: 0.5px solid $dark-gray;
					border-radius: 6px;
					font-weight: $font-bold;
					font-size: 15px;
					width: 100%;
					max-width: 180px;
					margin-top: 13px;
					outline: none;
					&:focus {
						box-shadow: none;
					}
				}
			}
		}
	}
}
.form-tage {
	width: 100%;
	border: 0.5px solid $dark-gray;
	border-radius: 6px;
	outline: none;
	resize: none;
	font-size: 15px;
	line-height: 40px;
	font-weight: $font-regular;
	color: $bunting;
}
.error-border {
	border: 0.5px solid red !important;
}
.form-tage:focus {
	border: 0.5px solid $dark-gray;
	box-shadow: none;
}
.contact-form {
	background-color: $light-red;
	padding: 20px 0;
}
