.modal-wrapper {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	background-color: rgba($color: $black-color, $alpha: 0.25);
	z-index: 10;
	display: flex;
	animation: fadeIn 0.3s;
	.modal-position {
		top: 5%;
	}

	.modal-container {
		position: relative;
		margin: 0 auto;
		box-sizing: border-box;
	}

	.body-wrapper {
		width: auto;
		height: auto;
		margin: 0 auto;
		background-color: $white-color;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
		border-radius: 12px;
	}
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
// imprint-popup
.imprint-modal {
	padding: 30px 35px;
	width: 530px;
	.imprint-title {
		font-size: 15px;
		line-height: 23px;
		color: $bunting;
		font-weight: $font-regular;
		text-transform: uppercase;
	}
	.quill {
		.ql-container {
			max-height: 150px;
			overflow-y: auto;
		}
	}
}

//conformation-popup
.conformation-popup {
	.conformation-popup-wrapper {
		width: 395px;
		.conformation-popup-div {
			padding: 30px 46px 25px;
			.tittle-wrapper {
				h3 {
					font-size: 15px;
					line-height: 23px;
					color: $bunting;
					font-weight: $font-bold;
				}
				p {
					font-size: 15px;
					line-height: 23px;
					color: $bunting;
					font-weight: $font-regular;
					margin: 0 0 20px;
				}
			}
			.conform-button {
				text-transform: uppercase;
			}
			.conform-close-button {
				font-size: 15px;
				line-height: 40px;
				color: $bunting;
				font-weight: $font-regular;
			}
		}
	}
	.form-reset-popup {
		width: 500px;
	}
}

.confirmation-modal-container {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: rgba($color: #000000, $alpha: 0.35);
	z-index: 10;

	.modal {
		top: 120px;
		left: 0;
		right: 0;
		height: auto;
		background-color: $white-color;
		padding: 2rem;
		display: block;
		backdrop-filter: blur(5px);
		box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
		width: 100%;
		height: auto;
		max-width: 538px;
		border-radius: 12px;
		margin: 0 auto;

		&_header {
			margin-bottom: 12px;
			position: relative;
			border-bottom: 1px solid #dddddd;
			&-title {
				text-align: center;
			}

			.modal_header-title {
				font-size: 15px;
				color: $bunting;
				margin-bottom: 20px !important;
			}
		}

		&_content {
			border-bottom: 1px solid #dddddd;
			padding: 2rem 0;
		}

		&_footer {
			padding: 5px 0;
			padding-bottom: 0;

			button {
				float: right;
				padding: 0.5rem;
				border-radius: 8px;
				font-size: 14px;
				line-height: 18px;
			}
		}
	}
}
