.custom-checkbox {
	padding-left: 35px;

	.custom-control-label {
		&::before,
		&::after {
			border-radius: 8px !important;
		}
	}
}

.custom-radio {
	input {
		display: none !important;
	}

	.custom-control-label {
		&::after {
			background-image: none;
			background-color: $white-color;
			left: -27px;
			top: 5px;
			height: 14px;
			width: 14px;
			border-radius: 50%;
		}
	}
}

.custom-control-label {
	cursor: pointer;

	&::before {
		left: -34px;
		top: -2px;
		height: 28px;
		width: 28px;
		border: 1px solid #979797;
		box-shadow: none !important;
	}

	&::after {
		left: -34px;
		top: -2px;
		height: 28px;
		width: 28px;
	}
}

.custom-control-input {
	z-index: 10;
	right: 0;
	left: auto;
	&:focus {
		outline: none;
	}
}

.custom-control-input:checked ~ .custom-control-label {
	&::before {
		border-color: #bbbbbb;
	}
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
	background-color: $white-color;
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
	border-color: #bbbbbb;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
	background-color: $primary-color;
	border-color: $primary-color;
}

.custom-control-input:checked ~ .custom-control-label {
	&::before {
		background-color: $primary-color;
		// border: none;
		border-color: $primary-color;
	}
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
	background-image: none;
}
