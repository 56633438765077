.fade-in-section {
	opacity: 0;
	// transform: translateY(20vh);
	visibility: hidden;
	transition: opacity 0.3s ease-out, visibility 0.5s ease-out;
	will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
	opacity: 1;
	visibility: visible;
}
