@import '../../../assets/css/variable.scss';
//user-login
.popup-close {
	padding: 10px 10px 0 0;
	width: fit-content;
	cursor: pointer;
}
.login-div {
	padding: 0 35px 30px 35px;

	.login-tittle-wrapper {
		text-align: center;
		h3 {
			font-size: 18px;
			font-weight: $font-bold;
			line-height: 23px;
			color: $yellow-sea;
		}
		p {
			font-size: 11px;
			font-weight: $font-regular;
			color: $bunting;
			line-height: 23px;
			margin: 43px 0 25px;
		}
	}
	.login-form-wrapper {
		display: flex;
		flex-direction: column;
		// margin: 24px 0;
		width: 100%;
		max-width: 332px;
		margin: 0 auto;
		.login-input {
			margin: 10px 0px 0px;
			.form-error {
				font-size: 11px;
				line-height: 23px;
				font-weight: $font-regular;
				color: $red;
			}

			label {
				font-size: 11px;
				line-height: 23px;
				font-weight: $font-regular;
				color: $bunting;
				// margin: 8px 0;
			}
			input {
				width: 100%;
				font-size: 11px;
				line-height: 23px;
				font-weight: $font-regular;
				color: #000000 !important;
				letter-spacing: 0px;
				height: 27px;
				border: 0.5px solid $dark-gray;
				border-radius: 6px;
				outline: none;
				padding: 5px 0 5px 10px;
			}
			.error-border {
				border: 0.5px solid red;
			}
		}
		.remain-signedIn-wrapper {
			display: block;
			position: relative;
			padding: 0;
			cursor: pointer;
			font-size: 22px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			p {
				font-size: 11px;
				line-height: 23px;
				font-weight: $font-regular;
				color: $bunting;
				margin-left: 24px;
				cursor: pointer;
			}
		}

		/* Hide the browser's default checkbox */
		.remain-signedIn-wrapper input {
			position: absolute;
			opacity: 0;
			cursor: pointer;
			height: 0;
			width: 0;
		}

		/* Create a custom checkbox */
		.checkmark {
			position: absolute;
			top: 0;
			left: 0;
			height: 18px;
			width: 18px;
			background-color: $white-color;
			border: 0.5px solid $dark-gray;
			border-radius: 3px;
			cursor: pointer;
		}

		/* On mouse-over, add a grey background color */
		.remain-signedIn-wrapper:hover input ~ .checkmark {
			background-color: $grey-4;
		}

		/* When the checkbox is checked, add a blue background */
		.remain-signedIn-wrapper input:checked ~ .checkmark {
			background-color: $white-color;
		}

		/* Create the checkmark/indicator (hidden when not checked) */
		.checkmark:after {
			content: '';
			position: absolute;
			display: none;
		}

		/* Show the checkmark when checked */
		.remain-signedIn-wrapper input:checked ~ .checkmark:after {
			display: block;
		}

		/* Style the checkmark/indicator */
		.remain-signedIn-wrapper .checkmark:after {
			left: 5px;
			top: 2px;
			width: 6px;
			height: 12px;
			border: solid #000000;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}

	.login-button {
		margin: 0 auto;
		font-size: 14px;
		line-height: 23px;
		font-weight: $font-bold;
		letter-spacing: 1px;
		text-transform: capitalize;
		width: 180px;
		height: 30px;
		padding: 4px 10px;
		background-color: $yellow-sea;
		border: 0.5px solid $dark-gray;
		color: $white-color;
		border-radius: 6px;
		margin-top: 46px;
	}
	.disabled-btn {
		cursor: not-allowed;
		background-color: $dark-gray;
		border: 0.5px solid #bebdbd;
	}
	.register-wrapper {
		font-size: 16px;
		font-weight: $font-bold;
		color: #111111;
		text-align: center;
		line-height: 21px;
		.register {
			color: $tangerine;
			border-bottom: 1px solid $tangerine;
			margin-left: 5px;
		}
	}
	.error-password {
		font-size: 14px;
		line-height: 16px;
		font-weight: 500;
		color: $red;
	}
	.login {
		font-size: 16px;
		line-height: 24px;
		font-weight: $font-regular;
		color: $tangerine;
		text-decoration-line: underline;
		cursor: pointer;
	}
	.forgotPassword {
		font-size: 14px;
		line-height: 24px;
		font-weight: $font-regular;
		color: #000000;
	}
}
.close-btn {
	top: 20px;
	right: 20px;
}
.security-wrapper {
	margin-top: 37px;
	h6 {
		font-size: 11px;
		font-weight: $font-regular;
		color: $bunting;
		margin-left: 5px;
	}
}
.user-email-wrapper {
	margin-top: 42px;
	h6 {
		font-size: 11px;
		color: $bunting;
		line-height: 23px;
		font-weight: $font-bold;
		margin-left: 5px;
	}
	p {
		font-size: 11px;
		line-height: 23px;
		font-weight: $font-regular;
		color: $bunting;
		margin-bottom: 20px;
	}
}
.login-register-wrapper {
	width: 395px;
	.login-register-div {
		padding: 22px 44px 35px;

		.login-register-form {
			padding-top: 33px;
			.login-input {
				label {
					font-size: 11px;
					color: $bunting;
					font-weight: $font-regular;
				}
				input {
					font-size: 11px;
				}
			}
		}
		.login-button {
			margin-top: 24px;
			width: 100%;
			font-size: 12px;
		}
		.forgotPassword {
			padding-top: 10px;
			font-size: 11px;
			font-weight: $font-regular;
			color: $bunting;
		}
		.forgot-password-tex-wrapper {
			margin: 40px 0 26px;
			p {
				font-size: 11px;
				font-weight: $font-regular;
				line-height: 23px;
				color: $bunting;
			}
		}
		.error-popup-wrapper {
			border: 1px solid $red;
			border-left: 6px solid $red;
			padding: 20px 0 10px;
			margin-top: 20px;
			.error-popup-icon {
				width: 20%;
				.warning-icon {
					fill: $red;
				}
			}
			.error-popup-text {
				width: 80%;
				h1 {
					font-size: 18px;
					line-height: 26px;
					font-weight: $font-regular;
					color: $bunting;
					text-align: center;
				}
				p {
					font-size: 11px;
					line-height: 26px;
					font-weight: $font-regular;
					color: $bunting;
					text-align: center;
				}
			}
		}
	}
	.forgot-password-div {
		padding-bottom: 63px;
	}
	.forgot-password-email-div {
		.forgot-password-tex-wrapper {
			margin: 40px 0 0;
		}
		.login-button {
			margin-top: 10px;
		}
		.collapse-text-wrapper {
			position: relative;
			input[type='checkbox'] {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
			}
			h2 {
				font-size: 11px;
				font-weight: $font-regular;
				color: $bunting;
				line-height: 23px;
				margin: 38px 0 20px;
			}
			label {
				cursor: pointer;
			}
			label {
				position: relative;
				display: block;
			}
			label::before {
				content: '';
				position: absolute;
				width: 10px;
				height: 10px;
				top: 50%;
				right: 0;
				border-width: 0.4vmin 0.4vmin 0 0;
				border-style: solid;
				border-color: $bunting;
				transform: rotate(130deg);
				margin-top: -8px;
				transition: 0.2s ease;
			}
			input[type='checkbox']:checked ~ h2 label::before {
				transform: rotate(-44deg);
				margin-left: -4px;
				margin-top: -4px;
				transition: 0.2s ease;
			}

			p {
				font-size: 10px;
				line-height: 23px;
				color: $bunting;
				max-height: 0;
				overflow: hidden;
				transition: max-height 0.4s ease;
			}
			input[type='checkbox']:checked ~ h2 ~ p {
				max-height: 80px;
			}
		}
	}
}
//user-verify-email
.verify-email-wrapper {
	width: 684px;
	.email-verify-container {
		padding: 0 50px 40px;

		.varify-tittle {
			font-size: 24px;
			font-weight: $font-regular;
			line-height: 23px;
			color: $yellow-sea;
			margin-top: 42px;
		}
		.verify-subtitle {
			font-size: 16px;
			font-weight: $font-regular;
			line-height: 23px;
			color: $bunting;
			margin: 23px 0;
		}
		.verify-butten {
			width: 100%;
			max-width: 225px;
			height: 38px;
			background: $yellow-sea;
			border: 0.5px solid $dark-gray;
			border-radius: 6px;
			color: $white-color;
			font-weight: $font-bold;
			font-size: 15px;
			line-height: 23px;
		}
		.disabled {
			opacity: 0.6;
			cursor: not-allowed;
		}
	}
}

//verification
.verification-wrapper {
	width: 684px;
	.verification-container {
		padding: 0 50px 40px;

		.verification-tittle {
			font-size: 24px;
			font-weight: $font-regular;
			line-height: 23px;
			color: $yellow-sea;
			margin-bottom: 30px;
		}
		.verification-subtitle {
			font-size: 16px;
			font-weight: $font-regular;
			line-height: 23px;
			color: $bunting;
			text-align: start;
		}
		.verification-butten {
			width: 100%;
			max-width: 225px;
			height: 38px;
			background: $yellow-sea;
			border: 0.5px solid $dark-gray;
			border-radius: 6px;
			color: $white-color;
			font-weight: $font-bold;
			font-size: 15px;
			line-height: 23px;
		}
	}
}
.verify-user-wrapper {
	.logo-imz {
		margin-left: 4%;
		padding-top: 2%;
		width: 78px;
	}
	.email-verify-container {
		margin: 0 auto;
		max-width: 509px;
		.verify-title {
			font-size: 30px;
			font-weight: $font-bold;
			line-height: 44px;
		}
		.verify-subtitle {
			font-size: 14px;
			line-height: 21px;
		}
		.mail-send-btn {
			height: 60px;
			margin: 0 auto;
			font-size: 16px;
			line-height: 24px;
			font-weight: $font-bold;
			letter-spacing: 1px;
			text-transform: capitalize;
			width: 250px;
			background-color: $bunting;
			color: #ffff;
			border-radius: 30px;
			margin-top: 20px;
		}
	}
}

//user-register
.register-wrapper {
	.register-tittle {
		background: linear-gradient(180deg, #ffb000 0%, #f38300 100%);
		border-radius: 8px;
		width: 100%;
		max-width: 568px;
		margin: 0 auto;
		padding: 15px;
		text-align: center;
		p {
			font-size: 24px;
			line-height: 28px;
			font-weight: $font-regular;
			color: $white-color;
		}
	}
	.login-wrapper {
		width: 100%;
		max-width: 395px;
		height: auto;

		.login-form-wrapper {
			width: 100%;
			max-width: 510px;
			margin: 0 auto;
			.login-input {
				label {
					font-size: 11px;
					line-height: 23px;
					font-weight: $font-regular;
					color: $bunting;
				}
				input {
					height: 28px;
					font-size: 11px;
					padding: 8px 0 8px 10px;
				}
			}
		}
		.login-div {
			.login-button-wrapper {
				margin-bottom: 14px;
			}
			.login-button {
				margin-top: 0;
				margin: 0;
			}
			.social-login-btn {
				.login-google {
					background-color: rgb(255, 255, 255);
					align-items: center !important;
					box-shadow: none !important;
					border: none !important;
					div {
						padding: 0 !important;
						svg {
							display: inline-block;
							width: 24px;
							height: 24px;
						}
					}
				}
				.kep-login-facebook {
					height: 24px;
					width: 24px;
					border-radius: 50% !important;
					padding: 0 !important;
					.fa {
						margin-right: 0;
					}
				}
			}
		}
	}
}

.pwd-div {
	width: 24%;
	height: 5px;
	margin-right: 10px;
}
.password-text {
	font-size: 12px;
	line-height: 23px;
	font-weight: $font-bold;
	color: $bunting;
}
.form-bar {
	cursor: pointer;
	position: absolute;
	right: 15px;
	width: 16px;
	height: 16px;
	top: 2px;
}

//forget-password
.login-wrapper {
	.forgot-password-wrapper {
		.login-tittle-wrapper {
			margin-bottom: 42px;
			h3 {
				font-size: 18px;
			}
		}
		.login-form-wrapper {
			max-width: 432px;
			.login-input {
				label {
					color: $bunting;
				}
				input {
					height: 33px;
				}
			}
		}
		.login-button {
			margin-top: 56px;
			margin-bottom: 44px;
		}
		.login {
			font-size: 14px;
			font-weight: $font-regular;
			line-height: 23px;
			color: #000000;
			margin-bottom: 40px;
		}
	}
}

//reset-password

.change-password-popup {
	.reset-password-wrapper {
		.login-tittle-wrapper {
			h3 {
				font-size: 18px;
			}
			p {
				width: 100%;
				max-width: 294px;
				margin: 0 auto;
			}
		}
		.login-form-wrapper {
			max-width: 432px;
			.login-input {
				margin: 14px 0;
				label {
					color: $bunting;
				}
				input {
					height: 33px;
				}
			}
		}
		.login-button {
			margin-top: 14px;
			margin-bottom: 16px;
			width: 226px;
		}
		.login {
			font-size: 14px;
			line-height: 23px;
			color: #000000;
		}
	}
}

.password-error-message-wrapper {
	.login-register-wrapper {
		.login-register-div {
			padding: 6px 21px 16px;
			.password-hint-message {
				font-size: 10px;
				font-weight: $font-regular;
				line-height: 15px;
				color: #000;
				padding-left: 5px;
			}
			.right-message {
				color: green;
			}
			.wrong-message {
				color: #f00;
			}
		}
		.close-btn {
			top: 5px;
			right: 6px;
		}
		.login-div {
			.login-tittle-wrapper {
				h3 {
					font-size: 11px;
				}
			}
		}
	}
}

.welcome-popup-wrapper {
	.login-div {
		h4 {
			font-size: 11px;
			font-weight: $font-bold;
			line-height: 23px;
			color: $bunting;
			padding: 30px 0 50px;
		}
		.verify-subtitle {
			font-size: 11px;
			font-weight: $font-regular;
			line-height: 23px;
			color: $bunting;
			padding-bottom: 15px;
			span {
				color: $bunting;
				font-weight: $font-bold;
			}
		}
		.mail-send-btn {
			border-radius: 6px;
			border: 0.5px solid $dark-gray;
			background: $yellow-sea;
			width: 100%;
			height: 30px;
			font-size: 12px;
			font-weight: $font-bold;
			line-height: 23px;
			color: $white-color;
		}
	}
}

.activation-user-popup-wrapper {
	width: 684px;
	.login-div {
		padding: 30px 65px 65px;
		.logo-wrapper {
			width: 230px;
			height: 91px;
			margin: 0 auto;
		}
		.login-tittle-wrapper {
			h3 {
				font-size: 24px;
				font-weight: $font-regular;
				padding-bottom: 30px;
			}
		}

		.verify-subtitle {
			font-size: 16px;
			font-weight: $font-regular;
			line-height: 23px;
			color: $bunting;
		}
		.mail-send-btn {
			border-radius: 6px;
			border: 0.5px solid $dark-gray;
			background: $yellow-sea;
			width: 100%;
			max-width: 234px;
			height: 38px;
			font-size: 15px;
			font-weight: $font-bold;
			line-height: 23px;
			color: $white-color;
			margin-top: 30px;
		}
	}
}

.user-register-popup {
	.confirm-wrapper {
		padding: 24px 0;
	}
}
