@import '~assets/css/variable.scss';

.card {
	padding: 10px;
	width: 20rem;
}

.card-div {
	width: 25%;
	display: inline-block;
}

.prop-link {
	font-weight: 550;
	color: rgb(90, 86, 86);
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.card-img,
.card-img-top {
	height: 145px;
}

.page-item.active .page-link {
	color: $white-color;
	border-color: $secondary-color;
	background-color: $secondary-color;
}

.page-link {
	color: #666666;
	border: 1px solid $secondary-color;
}

.text-info {
	color: $secondary-color !important;
}

.navbar-light .navbar-nav .nav-link {
	color: $white-color;
}

.pagination {
	li {
		margin-right: 15px;
		height: 30px;
		width: 30px;

		&:last-child {
			margin-right: 0px;
		}
	}

	a {
		cursor: pointer;
		color: $bunting !important;
		position: relative;
		display: block;
		text-align: center;
		line-height: 30px;
		font-size: 15px;
		font-weight: $font-bold;
	}

	a:hover {
		color: #666666;
	}

	.active a {
		color: $tangerine !important;

		&:hover {
			color: $tangerine !important;
		}
	}

	.paginate-btn {
		background-color: $white-color;
		height: 30px;
		width: 30px;
		line-height: 30px;
		border-radius: 30px;
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.28);
		transition: all 0.4s ease;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		.next-page-icon {
			color: $yellow-sea;
			&:hover {
				-webkit-transform: scale(1.3);
				-moz-transform: scale(1.3);
				-ms-transform: scale(1.3);
				-o-transform: scale(1.3);
				transform: scale(1.3);
			}
		}

		a {
			color: $grey-light;
			font-size: 25px;
			padding: 3px;
			line-height: 16px;
			transition: all 0.4s ease;
		}

		&.active {
			box-shadow: none !important;
			border-color: $primary-color;

			a {
				color: $primary-color;
			}
		}
	}
}

.page-bottom-fixed {
	bottom: 30px;
	background: $white-color;
	margin: 0 auto;
}

.pagination-width {
	width: fit-content;
	max-width: 30%;
}

.page-info-label {
	width: $full-width;
	text-align: center;
	color: $immozy-grey-blue;
}

.page-info-label-map {
	padding-bottom: 3%;
	width: 28%;
	text-align: center;
	color: $immozy-grey-blue;
}

.rounded-div {
	width: 75%;
}

.apply-btn-line {
	position: fixed !important;
	bottom: 0px !important;
	background: rgb(255, 255, 255) !important;
	z-index: 1000 !important;
	height: 62px;
}

.bottom-btn {
	position: relative;

	.button-filter,
	.button-apply {
		&:hover {
			background-color: $primary-color;
			color: $white-color;
		}

		&:active {
			background-color: $hover-red !important;
		}
	}

	.primary-btn {
		&:hover {
			color: $primary-color !important;
		}
	}

	.link-name {
		position: absolute;
		top: 12px;
		left: 55px;
	}
}

.active-btn {
	background-color: $primary-color !important;
	color: $white-color !important;
}

.button-filter,
.button-apply {
	height: 30px;
	width: 121px;
	background-color: $white-color;
	border: 1px solid #cdcdcd;
	border-radius: 5px;
	font-size: 11px;
	color: $bunting;
	font-weight: $font-bold;
	text-align: center;
	letter-spacing: 0.06em;
	box-shadow: $btn-box-shadow;
	transition: all 0.3s ease;

	&:hover {
		transition: background-color 0.5s ease 0s;
		box-shadow: $btn-box-shadow-hover;
	}

	svg {
		width: 23px;
		fill: $immozy-dark;
		margin-right: 5px;
	}
}
.reset-filter {
	border: none;
	font-size: 10px;
	line-height: 60px;
	font-weight: $font-regular;
	color: $bunting;
	width: 100%;
	.reset-icon {
		font-size: 17px;
		padding: 0 15px;
	}
}

.section-heading .button-wrapper {
	padding: 0 50px 0px 50px;
	.pointer-event {
		pointer-events: none;
	}
	.activeBtn {
		background-color: $tangerine;
		border-color: $tangerine;
		box-shadow: none !important;
		color: white !important;

		svg {
			fill: $white-color;
		}

		&:hover {
			background-color: $tangerine !important;
			border-color: $tangerine !important;
			transition: background-color 0.5s ease 0s;
		}
	}

	.activeBtnStar {
		color: $tangerine;
		font-size: 14px;
	}

	.top-btn {
		box-shadow: $btn-box-shadow;

		&:hover {
			background-color: $white-color;
			color: $tangerine;
		}
	}

	.top-btn-active {
		color: $white-color !important;
		background-color: $tangerine !important;
		border: 1px solid $tangerine !important;
		transition: all 0.3s ease;
		border-color: $tangerine !important;

		svg {
			fill: $primary-color;
		}

		&:hover {
			background-color: $tangerine !important;
			border: 1px solid $tangerine !important;
			color: $white-color;
		}
	}

	.more-btn-active {
		color: $white-color !important;
		background-color: $tangerine;
		border-color: $tangerine !important;

		svg {
			fill: $primary-color;
		}

		&:hover {
			background-color: $tangerine !important;
			border: 1px solid $tangerine !important;
			color: $white-color;
		}
	}
	.advertising-btn-wrapper {
		border: 0.5px solid $dark-gray;
		background-color: $bunting;
		border-radius: 5px;
		font-size: 14px;
		width: 121px;
		height: 30px;
		font-weight: $font-bold;
		margin: 0 20px;
		color: $white-color;
	}
}

.small-filter-modal.show-default-modal,
.small-filter-modal.open,
.small-filter-modal.open .filter-wrapper,
.small-filter-modal.show-default-modal .filter-wrapper {
	transform: translateY(0);
}

.small-filter-modal {
	width: 515px;
	background-color: $white-color;
	border-radius: 19px;
	box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 19px 0px;
	background-color: white;
	position: absolute;
	top: 60px;
	z-index: 999;
	padding: 15px 0;
	left: 150px;
	transition: all 0.4s ease;

	.graph-container {
		width: $full-width;

		rect {
			animation: fullHeight 0.6s;
		}
	}

	.input-range {
		width: 90%;
		margin: 0 auto;
	}
}

.small-filter-modal .filter-graph {
	width: $full-width;
	height: 100px;
	background-color: $light-red;
	margin-bottom: 15px;
}

.carousel-wrapper {
	height: $full-height;
	width: $full-width;
	position: relative;
}

.spin-img {
	margin-top: 35%;
	margin-right: 10px;
	height: 20px;
	width: 20px;
}

.top-details {
	line-height: 70px;
	margin-top: 15px;
	margin-left: 18px;

	img {
		object-fit: none;
		height: 30px;
	}

	p {
		font-weight: 600;
		font-size: 14px;
		color: rgba(78, 93, 117, 1);
		margin-right: 30px;
	}
}

.new-flag,
.inventory-badge-class {
	position: absolute;
	top: -1px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 1;
}

.inventory-badge-class {
	.badge-wrapper {
		background: linear-gradient(180deg, rgba(65, 103, 169, 0.2209) 0%, rgba(96, 99, 104, 0.47) 100%);
		border: 1px solid rgba(255, 255, 255, 0.43);
		border-radius: 0px 0px 12px 12px;
		height: 40px;

		p {
			color: #fff;
			text-transform: uppercase;
			font-size: 12px;
			font-weight: $font-light;
			letter-spacing: 0.6px;
		}
	}
}

.direct-badge-class {
	position: absolute;
	z-index: 2;
	left: 46%;
	animation: fadein 2s backwards;
	transition: 0.3s box-shadow ease-in-out;
}

.text-new {
	color: $white-color;
	top: 5px;
}

.disable-div-icon {
	position: absolute;
	height: 27px;
	width: 216px;
	left: 0;
	right: 0;
	top: 40%;
	margin-left: auto;
	margin-right: auto;
	background-color: $primary-color;
	color: $white-color;
	text-align: center;
	border-radius: 6px;

	font-size: 15px;
	font-weight: $font-bold;
	letter-spacing: 0.04em;
	line-height: 27px;
}

.inventory-badge {
	position: absolute;
	margin-top: -42px;
	z-index: 1;
	margin-left: 10px;

	&.shift-up {
		margin-left: 155px;
	}

	&.list {
		&.de {
			svg {
				width: 180px;
			}
		}

		svg {
			z-index: 9;
			width: 135px;
		}
	}
}

.score-icon {
	transition: all 0.5s ease;
	position: absolute;
	top: 10px;
	left: 10px;
	border-radius: 8px;
	height: 57px;
	width: 62px;
	text-align: center;
	z-index: 1;

	p {
		letter-spacing: 0.01em;
		line-height: 25px;
		font-size: 35px;
		font-weight: $font-bold;
		color: $white-color;
		margin-top: 15px;
	}
}

.sort-margin {
	margin-left: 25px;
}

.like-icon {
	transition: all 0.5s ease;
	position: absolute;
	top: 10px;
	right: 5px;
	cursor: pointer;
	background-color: $white-color;
	box-shadow: $btn-box-shadow;
	border-radius: 50%;
	height: 33px;
	width: 33px;
	z-index: 1;
	will-change: scroll, opacity, transform;

	&:hover {
		box-shadow: $btn-box-shadow-hover;

		i {
			color: $primary-color;
			font-size: 22px;
			font-weight: $font-bold;
			line-height: 36px;
			transition: all 0.2s ease-in-out;
		}
	}

	.fa-heart {
		color: $primary-color;
	}

	i {
		font-size: 20px;
		width: 33px;
		line-height: 34px;
	}

	i:hover {
		color: $primary-color !important;
		font-size: 22px;
		font-weight: $font-bold;
		transition: all 0.1s ease-in-out;
	}
}

.location {
	font-weight: 600;
	color: $immozy-grey-blue;
	text-transform: capitalize;
	letter-spacing: 0.05em;
	margin-left: 18px;

	li {
		&:first-child {
			font-size: 16px;
			letter-spacing: 0.01em;
			line-height: 14px;
			margin-bottom: 5px;
			text-transform: none;
		}
	}
}

.price-label {
	font-size: 19px;
	color: $immozy-grey-blue;
	letter-spacing: inherit;
	font-weight: 600;
	float: right;
	margin-right: 10px;
	line-height: 1;
	margin-top: -2px;

	li {
		font-size: 13px;
		color: $border-grey;
		text-align: center;
		letter-spacing: 0.05em;
		line-height: 17px;
		text-align: inherit;
	}
}

.property-info-wrapper {
	margin-bottom: 20px;
}

.property-info {
	p {
		color: #484848;
		position: relative;
		margin: 0 10px 10px;
		font-size: 16px;
	}

	p span {
		font-weight: 600;
	}

	p:first-child {
		margin-left: 0;
	}
}

.chart-wrapper {
	width: 60px;
	height: 60px;
	position: relative;
}

.property-card {
	margin-bottom: 20px;
	border-bottom: 1px solid $grey-4;
	padding-bottom: 20px;
	border-top-left-radius: 4px;
	height: 333px;
	width: calc(100% - 20px);
	margin: 15px;
	border-radius: 15px;
	box-shadow: 0 3px 20px -1px #cecece;
	overflow: hidden;
	animation: fadein 2s backwards;
	transition: 0.3s box-shadow ease-in-out;
	.property-edit-icon {
		position: absolute;
		top: 12px;
		z-index: 10;
		right: 16px;
		background: #ffffffb5;
		padding: 7px;
		border-radius: 6px;
		display: none;
		.edit-icon {
			color: $grey-light;
		}
	}

	&:hover {
		box-shadow: 0 3px 20px -1px grey;

		.swiper-button-prev,
		.swiper-button-next {
			opacity: 1 !important;
			z-index: 3;
		}
		.property-edit-icon {
			display: block;
		}
	}

	.hide-arrow {
		.swiper-button-prev,
		.swiper-button-next {
			display: none;
		}
	}

	&:last-child {
		border-bottom: 0;
	}

	.swiper-wrapper-div.hide-bullets {
		.swiper-pagination {
			opacity: 1 !important;
		}
	}

	.swiper-wrapper-div.hide-bullets-hide {
		.swiper-pagination {
			opacity: 0 !important;
		}
	}

	.swiper-wrapper-div {
		height: 100%;
		width: 100%;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;

		.swiper-slide {
			// background-color: #f5f5f5;
			height: 100%;

			img {
				width: 100%;
				height: 268px;
				border-top-left-radius: 15px;
				border-top-right-radius: 15px;
				object-fit: cover;
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			height: 33px;
			width: 33px;
			background-color: $white-color;
			border-radius: 50%;
			text-align: center;
			opacity: 0;
			transition: all 0.3s ease;
			transform: translateY(43px);
			z-index: 11;
			box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0);

			&::after {
				display: none;
			}

			&:hover {
				box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.12);
			}

			svg {
				width: 33px;
				height: 33px;
				border-radius: 50%;
				padding: 8px 5px 8px 3px;
			}

			&.swiper-button-next {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		.swiper-pagination {
			opacity: 0;
		}

		.swiper-pagination-bullet {
			background-color: $bg-white;

			&.swiper-pagination-bullet-active {
				background-color: $white-color;
			}

			&.swiper-pagination-bullet-active-prev,
			&.swiper-pagination-bullet-active-next {
				transform: scale(0.76);
				opacity: 0.4 !important;
			}

			&.swiper-pagination-bullet-active-next-next,
			&.swiper-pagination-bullet-active-prev-prev {
				transform: scale(0.6);
				opacity: 0.4 !important;
			}
		}
	}

	.image-gallery-bullets {
		width: 21%;
		height: 15px;
		overflow: hidden;
		z-index: 0;
		padding: 3.5px;

		.image-gallery-bullets-container {
			.image-gallery-bullet:nth-child(5) {
				padding: 3px !important;
				margin-top: 3px !important;
			}
		}

		.image-gallery-bullet {
			border: none !important;
			box-shadow: none !important;
			padding: 3.5px !important;

			&:nth-child(5) {
				margin: 0px 7px;
			}
		}
	}

	.image-gallery-bullet {
		margin: 5px;
		box-shadow: none;
		padding: 4px;
		background-color: #d6d6d6 !important;

		&:hover,
		&.active {
			background-color: $white-color !important;
			padding: 3.5px !important;
		}
	}

	.image-gallery-left-nav,
	.image-gallery-right-nav {
		transform: translateY(19px);
	}
}
@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes slidein {
	0% {
		opacity: 0;
	}

	25%,
	75% {
		opacity: 1;
	}

	100% {
		opacity: 1;
	}
}

@keyframes example {
	50% {
		color: $primary-color;
	}
}

.anim-number {
	animation-name: example;
	animation-duration: 1s;
}

.range-item {
	.range-slider-value {
		position: absolute;
		bottom: -40px;
		padding: 2px 0px;
		background: $primary-color;
		z-index: 999;
		color: $white-color;
		font-size: 16px;
		text-align: center;
		letter-spacing: 0.01em;
		line-height: 25px;
		width: 42px;
		border-radius: 8px;

		&::before {
			content: '';
			position: absolute;
			top: -10px;
			left: 14px;
			border-bottom: 10px solid $primary-color;
			border-left: 7px solid transparent;
			border-right: 7px solid transparent;
		}
	}

	.input-range__label--max .input-range__label-container {
		left: 0%;
	}

	.input-range__label--value {
		display: none;
	}
}

.dash-filter {
	font-size: 30px;
	font-weight: 500;
	color: grey;
	margin: 2px;
}

.minmaxbtn {
	width: 85%;
	margin: 0px auto;
}

.inputbox-div-top {
	.form-control:disabled,
	.form-control[readonly] {
		background-color: $white-color !important;
	}
}

.room-filter-section {
	width: 85%;
	margin: 0px auto;

	li:nth-child(2) {
		text-align: center;
	}

	img {
		margin-top: 30px;
		cursor: pointer;
	}
}

.inputbox-div {
	width: 250px;
}

.inputbox-div-top {
	width: 120px;

	&.active {
		border-color: $primary-color !important;

		input {
			color: $primary-color !important;
			font-weight: $font-bold;
		}
	}
}

.inputbox-div,
.inputbox-div-top {
	border: 1px solid $border-grey;
	border-radius: 9px;
	height: 74px;

	&.focused {
		border: 1px solid $primary-color;
	}

	&:hover,
	&.focused {
		.symbol-left,
		input {
			color: $primary-color !important;
			font-weight: $font-bold;
		}
	}

	.form-control {
		border: none !important;
		box-shadow: none !important;

		&:disabled {
			background-color: $white-color !important;
		}
	}

	.form-control.show-pad {
		padding-left: 50px;
	}

	span {
		float: left;
		font-size: 14px;
		color: rgba(48, 56, 71, 1);
		letter-spacing: 0.06em;
		margin-left: 10px;
		margin-top: 5px;
		text-transform: capitalize;
	}

	.form-control,
	p {
		font-size: 16px !important;
		color: rgba(48, 56, 71, 1);
		letter-spacing: 0.06em;

		&:hover {
			color: $primary-color !important;
		}
	}

	.symbol-left {
		position: absolute;
		top: 34px;
		padding-left: 10px;
		font-size: 14px !important;
		padding-top: 1px;
	}
}

.live-counter {
	font-size: 20px;
	color: rgba(48, 56, 71, 1);
	text-align: center;
	letter-spacing: 0.06em;
	line-height: 18px;
	font-weight: $font-bold;
}

.advance-op {
	float: left;
	margin-left: 20px;
	font-size: 14px;
	color: rgba(55, 75, 106, 1);
	text-align: center;
	letter-spacing: 0.06em;
	line-height: 18px;
	padding-top: 10px;
}

.advance-op-up {
	float: left;
	margin-bottom: 15px;
	margin-left: 20px;
}

.top-matches-container,
.top-matches-container-small {
	height: 355px;
	top: 45px;
	left: -165px;

	&::before {
		content: '';
		position: absolute;
		top: -15px;
		left: 230px;
		border-bottom: 20px solid $white-color;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
	}
}

.top-matches-container {
	height: 655px;
}

.clear-btn {
	float: right;
	font-size: 14px;
	color: $secondary-color;
	text-align: center;
	letter-spacing: 0.06em;
	line-height: 18px;
	margin-top: 8px;
	cursor: pointer;
	text-decoration: underline;
	transition: all 0.3s ease;

	&:hover {
		color: $immozy-lightblue;
	}

	&:focus {
		color: $primary-color;
	}
}

.small-filter-title1 {
	font-size: 18px;
	font-weight: bold;
	color: rgba(48, 56, 71, 1);
	letter-spacing: 0.06em;
	line-height: 18px;
	text-align: center;
}

.title-finder {
	font-size: 14px;
	font-weight: bold;
	color: rgba(79, 89, 108, 1);
	letter-spacing: 0.06em;
	line-height: 18px;
	width: 35%;
}

.title-finder-info {
	font-size: 14px;
	color: rgba(79, 89, 108, 1);
	letter-spacing: 0.06em;
	line-height: 28px;
	text-decoration: underline;

	&:hover {
		color: $primary-color;
	}

	i {
		cursor: pointer;
	}
}

.small-filter-title2 {
	font-weight: bold;
	color: rgba(55, 75, 106, 1);
	text-align: center;
	letter-spacing: 0.06em;
	line-height: 18px;
	text-align: center;
}

.symbol-right-plus {
	position: absolute;
	top: 32px;
	font-size: 20px !important;
}

.small-filter-modal input::-webkit-outer-spin-button,
.small-filter-modal input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield !important;
}

.error-ans {
	font-size: 13px;
	color: $primary-color;
	margin-bottom: 5px;
}

.price-div {
	left: -36px;
	top: 35px !important;
}

.area-div {
	left: 100px;
}

.rooms-div {
	left: 235px;
}

.price-div-de {
	left: -202px !important;
	top: 30px !important;
}

.area-div-de {
	left: -66px;
}

.rooms-div-de {
	left: 60px;
}

.area-div,
.price-div,
.rooms-div,
.area-div-de,
.price-div-de,
.rooms-div-de {
	top: 38px;

	&::before {
		content: '';
		position: absolute;
		top: -15px;
		left: 230px;
		border-bottom: 20px solid $white-color;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
	}
}

.verify-mail-div {
	align-items: center;
	display: inline-flex;
	width: $full-width;
}

.verify-mail-div button {
	height: 35px;
	font-size: 0.875rem;
}

.reset-title {
	font-size: 14px;
	color: $secondary-color;
	letter-spacing: 0.06em;
	line-height: 21px;
}

.more-filter-container {
	padding: 0;

	.more-filter-label {
		font-size: 16px;
		font-weight: bold;
		color: $grey-light;
		letter-spacing: 0.06em;
		line-height: 18px;
		text-align: center;
		padding: 16px 0px;
		margin: 0;

		border-top-left-radius: 45px;
		border-top-right-radius: 45px;

		.icon-check {
			height: 25px;
			fill: green;
		}
	}

	.save-search-link {
		text-align: center;
		font-size: 14px;
		text-decoration: underline;
		color: rgb(55, 75, 106);
		float: left;
		margin-top: 15px;

		&:hover {
			color: $primary-color;
		}
	}

	.close-btn {
		position: absolute;
		right: 10px;
		margin: 5px 15px;
		border-radius: 25px;
		top: 12px;
		transition: all 0.2s ease;

		&:hover {
			box-shadow: 0px 2px 4px 3px rgba(0, 0, 0, 0.15);
		}
	}

	.my-search-div {
		.form-control {
			width: 50%;
			color: $primary-color;
			font-weight: $font-bold;
			letter-spacing: 0.06em;
			line-height: 18px;
			margin-bottom: 10px;
			background-color: $bg-white;
			border: 1px solid #979797;
			border-radius: 8px;
			height: 30px;
		}

		.name-label {
			width: 70%;
			color: $primary-color;
			font-weight: $font-bold;
			letter-spacing: 0.06em;
			line-height: 18px;
		}

		.sub-label {
			font-size: 14px;
		}

		li {
			margin: 10px 0px !important;
		}

		.detail-text {
			white-space: pre;
			width: 50%;
			max-width: 50%;
			text-overflow: ellipsis;
		}

		.setting-label {
			font-size: 14px;
			color: rgba(78, 93, 117, 1);
			letter-spacing: 0.06em;
			line-height: 28px;
			text-decoration: underline;

			&:hover {
				color: $primary-color;
				cursor: pointer;
			}
		}

		.setting-label.active {
			color: $primary-color;
			font-weight: $font-bold;
		}
	}

	.filter-data-container {
		height: 90%;
		overflow-y: scroll;
		padding: 0 !important;

		.radius-slider {
			.input-range--disabled {
				.input-range__slider {
					background-image: url('../../assets/images/slider-handle-disable.png') !important;
					background-repeat: no-repeat;
					pointer-events: none;
				}

				.input-range__track {
					pointer-events: none !important;
				}
			}

			.range-slider-value:nth-child(2) {
				display: none;
			}

			.range-item .range-slider-value {
				width: 60px !important;
			}
		}

		.filter-data-item {
			box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.09);
			padding: 0 4%;

			&.notifications {
				box-shadow: none;
			}

			.search-input-container .search-input-container__inner {
				position: relative !important;
				border: 1px solid #979797;
				border-radius: 8px;
				margin-top: 15px;
			}
		}

		.radius-section {
			.search-input-container .suggestion-input {
				width: 100% !important;
				padding: 7px;
			}

			.handleContainer_1 .DefaultHandle_handle:nth-child(2) {
				pointer-events: none !important;
				border: 1px solid #d8d8d8 !important;

				&::before {
					background-image: url('../../assets/images/slider-handle-disable.png') !important;
					background-repeat: no-repeat;
				}
			}

			.suggestions-container {
				z-index: 1005 !important;
			}
		}

		.checkbox-wrapper {
			display: flex;
			flex-wrap: wrap;

			&.internet-speed {
				display: block;

				label {
					display: inline-block;
				}
			}

			.form-group {
				width: 50%;
				margin-bottom: 19px;
			}
		}

		.icon-arrow-left {
			width: 28px;
			height: 30px;
			float: left;
			margin-top: 20px;
			margin-left: 10%;
			fill: #a4a4a4;

			&:hover {
				fill: $primary-color;
			}
		}
	}
}

.slider-div {
	width: calc(74% - 30px);
	margin: 0px auto;
	margin-top: 20px;
	margin-bottom: 10px;
	padding: 0px 15px;

	.range-slider-value {
		font-size: 16px !important;
		bottom: -47px !important;
		transition: all 0.3s ease-out;
	}

	.input-range__label-container {
		display: none !important;
	}

	.input-range__slider {
		width: 30px !important;
		height: 30px !important;
		border: 1.5px solid #b5bec5 !important;
		background-color: #fcfcfc !important;
		border-radius: 100% !important;
		outline: none !important;
		z-index: 2;
		box-shadow: 0 2px 2px #dbdbdb;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		margin-top: -15px !important;

		&:active {
			transform: none !important;
		}

		&::before {
			content: '\f0c9';
			font-family: 'FontAwesome';
			-webkit-transform: rotate(90deg);
			-moz-transform: rotate(90deg);
			-ms-transform: rotate(90deg);
			-o-transform: rotate(90deg);
			transform: rotate(90deg);
			text-align: center;
			font-size: 17px;
			width: 100%;
			height: 100%;
			color: #545f74;
			font-weight: normal;
		}

		&:hover,
		&:focus {
			box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.12);
			&::before {
				color: $tangerine;
			}
		}
	}
}

.slider-p {
	margin-top: 5px;
}

.title-header {
	font-size: 18px;
	font-weight: bold;
	color: rgba(48, 56, 71, 1);
	letter-spacing: 0.06em;
	line-height: 18px;

	.prop-types-tab {
		p {
			font-size: 14px;
			font-weight: bold;
			text-align: center;
			letter-spacing: 0.06em;
			line-height: 18px;
			text-decoration: underline;
			cursor: pointer;
			padding: 1px 9px 3px;
			border-radius: 11px;

			&.active {
				color: $primary-color;
				background-color: $bg-white;

				&:hover {
					color: $primary-color;
				}
			}

			&:hover {
				color: #a4a4a4;
			}
		}
	}
}

.more-error {
	font-size: 14px;
	color: $red;
	margin-left: 5px;
}

.no-my-search-sub {
	text-align: center;
	margin-top: 30px;
	margin-bottom: 10px;
}

.ani-rotate-360 {
	animation: rotate360 1s linear;
	animation-iteration-count: 1;
}

@keyframes rotate360 {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.gmnoprint {
	div {
		background-color: transparent !important;
		border-radius: 30px !important;
		box-shadow: none !important;

		div {
			div {
				display: none !important;
			}
		}
	}
}

#content-multi {
	height: 15px !important;
	font-size: 14px !important;
	color: $grey-light !important;
	text-align: center !important;
	font-family: $font !important;
	overflow: hidden !important;
	line-height: 12px !important;
}

.map-box {
	div {
		border: none !important;
		outline: none !important;
	}

	.gm-ui-hover-effect {
		display: none !important;
	}

	.gm-control-active {
		position: relative;
		background-color: $white-color !important;
		border: 1px solid $border-grey !important;
		box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.28);

		&::before {
			position: absolute;
			content: '';
			background-size: contain;
			height: 17px;
			width: 17px;
			top: 12px;
			left: 11px;
			color: $white-color;
		}

		&:first-child {
			border-top-left-radius: 30px;
			border-top-right-radius: 30px;
			border-bottom-left-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
			border-bottom: none !important;

			&::before {
				background-image: url('../../assets/images/plus.png');
			}
		}

		&:last-child {
			border-bottom-left-radius: 30px;
			border-bottom-right-radius: 30px;
			border-top-left-radius: 0 !important;
			border-top-right-radius: 0 !important;

			&::before {
				background-image: url('../../assets/images/minus-button.png');
				top: 10px;
			}
		}

		img {
			display: none !important;
		}
	}
}

@keyframes fullHeight {
	from {
		y: 100%;
	}

	to {
		y: var(--element-svg-y-height);
	}
}

.DefaultProgressBar_progressBar__vertical {
	height: 100%;
	width: 24px;
}

.DefaultProgressBar_background__vertical {
	height: 100%;
	top: 0px;
	width: 15px;
}

.rheostat__vertical {
	height: 100%;
}

.handleContainer {
	height: 15px;
	top: -2px;
	left: -2px;
	bottom: 4px;
	width: 100%;
	position: absolute;
}

.rheostat_background {
	background-color: #fcfcfc;
	border: 1px solid #d8d8d8;
	position: relative;
}

.rheostat_background__horizontal {
	height: 15px;
	top: -2px;
	left: -2px;
	bottom: 4px;
	width: 100%;
}

.rheostat_background__vertical {
	width: 15px;
	top: 0px;
	height: 100%;
}

.popper-inner {
	.PriceFilter {
		padding: 20px 20px 0;
	}
}

.PriceFilter {
	flex-direction: column;
	max-width: 280px;
	padding: 20px 20px 0;
}

.Slider {
	min-width: 100%;
}

.PriceFilter-priceFieldsOuter {
	display: flex;
	margin-top: 20px;
	justify-content: center;
}

.DefaultHandle_handle__horizontal {
	margin-left: -12px;
	top: -12px;
}

.DefaultProgressBar_background__horizontal {
	height: 2px;
	top: 0;
}

.DefaultProgressBar_progressBar {
	background-color: #732dd9;
	position: absolute;
}

.banner-div {
	width: 100%;
	position: sticky;
	top: 125px;
	z-index: 2;
	transition: all 0.2s ease;
	max-width: 1920px;

	img {
		width: 100%;
		height: 155px;
		object-fit: cover;
		max-width: 1920px;
	}

	p {
		position: absolute;
		left: 22%;
		color: $white-color;

		&.banner-title {
			font-size: 35px;
			top: 22%;
			font-weight: $font-bold;
		}

		&.banner-subtitle {
			font-size: 16px;
			top: 60%;
			line-height: 21px;
			letter-spacing: 0.01em;
		}
	}

	.close-btn-div {
		top: 40px;
		border: 2px solid $white-color;
		cursor: pointer;

		svg,
		path {
			fill: $white-color;
		}

		&:hover {
			svg {
				fill: $white-color;
			}
		}
	}

	.banner-btn {
		position: absolute;
		left: 65%;
		top: 50%;
		color: $white-color;

		width: 165px;
		height: 40px;
		border-radius: 18px;
		font-size: 14px;
		text-align: center;
		letter-spacing: 0.06em;
		box-shadow: 0 0 4px 1px transparent;
		transition: all 0.3s ease;
		background-color: $white-color;
		box-shadow: none;
		color: $primary-color;
		margin-right: 15px;
		border-radius: 21px;
		font-weight: $font-bold;

		a:hover {
			color: $primary-color !important;
		}

		&:hover {
			box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.12);
		}
	}
}

@media (min-width: 1146px) {
	.PriceFilter {
		align-items: center;
		overflow: hidden;
		min-width: 200px;
	}
}

@media (min-width: 1128px) {
	.autoAdjustVerticalPosition {
		top: 12px;
	}
}

@media (min-width: 992px) {
	.DefaultHandle_handle {
		width: 20px;
		height: 20px;
	}

	.DefaultHandle_handle__horizontal {
		margin-left: -10px;
		top: -8px;
	}
}

.cluster-0,
.cluster-1,
.cluster-2,
.cluster-3,
.cluster-4,
.cluster-5,
.cluster-6,
.cluster-7 {
	div {
		line-height: 45px !important;
	}
}

//------------------------ skeleton - loading---------------------------

.skeleton-property-card {
	.property-detail-wrapper {
		padding: 20px;

		.area-room-spec {
			width: 60%;
		}

		.price-spec {
			width: 40%;
		}
	}
}

.section-heading {
	padding: 5px 15px 15px;
	text-align: center;

	.title {
		position: relative;
		font-size: 18px;
		color: $bunting;
		line-height: 60px;
		letter-spacing: 0.04em;
		font-weight: $font-bold;
		min-width: 210px;
	}

	h4 {
		font-weight: $font-regular;
	}
}

.title-text-show {
	display: inline-flex;
}

.curved-div {
	position: relative;
	background: white;
	width: 100%;
	max-width: 1920px;
	padding: 0 10px;
	transition: all 0.2s ease-in-out;

	&.fixed {
		position: fixed;
		top: 30px;
		transition: all 0.6s ease;

		.section-heading {
			background-color: $white-color;
		}
	}

	&.favorites {
		position: fixed;
		top: 80px;
		padding: 5px;
	}

	.spinner-wrapper {
		position: absolute;
		right: -60px;
		top: -5px;
		display: contents;
	}
}

.search-result-section {
	background-color: $white-color;
	min-height: calc(100vh - 100px);
	position: relative;
}

.search-result-section .load-div {
	height: calc(100% - 61px);
	min-height: auto;
}

.header .row {
	margin: 0;
}

.filter-button {
	position: relative;
	min-height: 24px;
}

.filter-button button {
	width: 100px;
	border: none;
	position: absolute;
	top: -2px;
}

.filter-button button:before {
	content: 'Filter';
	width: $full-width;
	height: $full-height;
	position: absolute;
	top: 0;
	letter-spacing: 0.5px;
	padding-left: 5px;
	padding-top: 1px;
}

.property-result-wrapper {
	width: $full-width;
	position: relative;
	.top-search-section {
		background-color: $bunting;
		.top-search-wrapper {
			display: flex;
			align-items: center;
			margin: 0 6px;

			.search-filters {
				width: 91%;
			}

			.top-search-title {
				color: $white-color;
				font-weight: $font-regular;
				font-size: 11px;
				line-height: 60px;
				margin-right: 5px;
			}
		}
	}
	.user-location-wrapper {
		width: 268px;
		position: relative;
		margin: 0 6px;
		.location-result-wrapper {
			width: 100%;
			height: 100%;
			max-height: 220px;
			overflow: auto;
		}

		.arrow-wrapper {
			position: absolute;
			top: 0;
			right: 18px;
			z-index: 10;
		}
		.cross-wrapper {
			position: absolute;
			top: 3px;
			right: 15px;
			z-index: 10;
		}
		.result-wrapper {
			cursor: pointer;
			.result-span {
				padding: 0 10px;
				white-space: nowrap;
			}
		}
	}
}

.search-result-section {
	.map-wrapper {
		position: absolute;
		width: 71.5%;
		right: 0;
		height: 100vh;
		max-width: 1370px;

		&.sticky {
			position: fixed;
			top: 0;

			.full-screen-btn {
				top: 155px;
			}
		}

		&.sticky-full-map {
			height: 100%;
		}

		&.sticky-full-map-fav {
			height: calc(100vh - 170px) !important;
		}

		&.sticky.sticky-hide {
			overflow-y: hidden !important;
		}

		&.sticky-footer {
			position: absolute;
			top: auto;
			bottom: 143px;
		}
	}
}

.no-show {
	display: none;
}

.map-wrapper .map-content,
.map-wrapper .map-content .map-box {
	width: $full-width;
	height: $full-height;
}

.mapborder::after {
	content: '';
	position: absolute;
	width: $full-width;
	height: $full-height;
	background-color: rgba(255, 255, 255, 0.5);
	z-index: 999;
	top: 0;
	cursor: not-allowed;
}

.full-screen-btn {
	position: absolute;
	z-index: 1;
	top: 80px;
	right: 5px;
	background-color: $white-color;
	padding: 5px 12px;
	border-radius: 50%;
	height: 49px;
	width: 49px;
	text-align: center;
	line-height: 34px;
	box-shadow: $btn-box-shadow-hover;
	border: 1px solid $border-grey;
	transition: all 0.3s ease;

	&:hover {
		border-color: $primary-color;

		svg {
			fill: $primary-color;
		}
	}

	svg {
		width: 22px;
		height: 22px;
		fill: $grey-light;
		transition: all 0.3s ease;
	}

	&.full-screen-btn-fixed {
		position: fixed;
		top: 230px !important;
	}
}

.search-result-section .pagination {
	justify-content: center;
	height: 79px;
	width: 100%;
	padding: 24px 20px 0px 20px;
}

.map-pagination {
	margin-bottom: 15px;
	margin: 0 auto 15px;
	max-width: 100%;
}

.prev-class-map a {
	margin-left: 4%;
}

.favourites-section .map-wrapper {
	height: 99.5%;
}

.button-wrapper {
	.more-filter {
		top: 38px;
		left: 154px;

		&::before {
			content: '';
			position: absolute;
			top: -15px;
			left: 275px;
			border-bottom: 20px solid $white-color;
			border-left: 25px solid transparent;
			border-right: 25px solid transparent;
			-webkit-filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
			filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
			z-index: 201;
		}
	}
}

.filter-modal,
.preference-modal,
.search-profile-modal,
.edit-pref {
	width: $full-width;
	top: 65px;
	right: 0;
	z-index: -1;
	height: auto;
	position: absolute;
	opacity: 1;
	transform: translateY(-100vh);

	&.fade-out {
		-webkit-animation-duration: 0.4s !important;
		animation-duration: 0.4s !important;
	}

	&.fade-in {
		-webkit-animation-duration: 0.4s !important;
		animation-duration: 0.4s !important;
	}

	&.open {
		z-index: 11;
		opacity: 1;
		transform: translateY(0);
	}

	&.close-model {
		z-index: -1;
		opacity: 0;
		transform: translateY(-100vh);
		transition-delay: 0.5s !important;
	}
}

.filter-modal.save-search-modal {
	left: 198px;

	&::before {
		content: '';
		position: absolute;
		top: -15px;
		margin-left: -35px;
		z-index: 201;
		border-bottom: 20px solid $white-color;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		-webkit-filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
		filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
	}

	&.germen-model {
		left: 225px;
	}

	.custom-control-label {
		font-size: 14px !important;
		color: $grey-light !important;
		line-height: 21px !important;
	}

	.filter-wrapper {
		width: 446px !important;
		height: 182px !important;
	}
}

.filter-modal.reset-modal {
	left: 365px;
	top: 45px;

	&.germen-model {
		&::before {
			left: 390px;
		}
	}

	&::before {
		content: '';
		position: absolute;
		top: -15px;
		left: 365.5px;
		z-index: 201;
		border-bottom: 20px solid $white-color;
		border-left: 25px solid transparent;
		border-right: 25px solid transparent;
		-webkit-filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
		filter: drop-shadow(0 -4px 3px rgba(0, 0, 0, 0.1));
	}
}

.filter-modal {
	.show-default-modal {
		position: relative;
		display: block;
	}
}

.modal-content-wrapper {
	width: 70%;
	background-color: $white-color;
	margin: 0 auto;
	padding: 20px 0;
	box-shadow: 0 7px 24px 0 rgba(0, 0, 0, 0.2);
	border-radius: 34px;
	position: relative;

	.container {
		padding: 0 30px;
		width: $full-width;
		max-width: 100%;
	}

	.heading-text {
		padding: 0 0 15px;
		margin-bottom: 15px;
		border-bottom: 1px solid #cecece;
		font-weight: 500;
	}

	.match-find-location {
		box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.09);
		padding: 10px 5%;

		&:last-child {
			box-shadow: none !important;
		}

		ul {
			text-align: initial;
			padding-left: 10%;

			li {
				margin-bottom: 5px;
				display: flex;
			}
		}

		img {
			margin-top: -10px;
			margin-left: 15px;
		}

		h6 {
			padding-left: 4%;
		}

		.btn-edit i {
			font-size: 15px;
			margin-left: 5px;
			color: $primary-color;
		}
	}
}

.criteria-info-wrapper {
	width: 50%;
	position: relative;
	padding-left: 20px;
}

.criteria-info-wrapper .heading {
	margin: 50px 0;
}

.notification-info .form-group {
	width: 25px;
	height: 25px;
}

.notification-info .form-group input {
	margin-top: 0;
}

.criteria-info-wrapper .score-dropdown-wrapper .custom-dropdown-item {
	min-height: 46px;
}

.modal-content-wrapper .close-btn {
	position: absolute;
	top: 10px;
	right: 15px;
}

.scores-weightage-wrapper .scores-list {
	width: 70%;
	margin: 0 auto;
}

.scores-weightage-wrapper .weightage-graph {
	width: 50%;
	height: 150px;
	padding-left: 10%;
	padding-top: 2%;
}

.scores-list .range-item .icon {
	width: 40px;
	height: 40px;
	border: 1px solid #cecece;
	border-radius: 50%;
}

.scores-list .range-item .form-group {
	width: calc(100% - 60px);
}

.scores-list .range-item .icon i {
	color: #cccccc;
	font-size: 22px;
	margin: 0;
}

.form-control-range {
	cursor: pointer;
}

.filter-modal.show-default-modal,
.filter-modal.open,
.filter-modal.open .filter-wrapper,
.filter-modal.show-default-modal .filter-wrapper {
	transform: translateY(0);
}

.filter-wrapper {
	width: 90%;
	max-width: 992px;
	background-color: $white-color;
	margin: 0 auto;
	width: 584px;
	box-shadow: 0px 7px 24px 0px rgba(0, 0, 0, 0.2);
	border-radius: 4px;
	right: 5px;
	border-radius: 20px;
	overflow: hidden;

	.checkbox-wrapper {
		.form-check {
			padding: 0;
			display: flex;
		}

		.form-check-input {
			position: relative;
			cursor: pointer;
			margin: 0;
		}

		.form-check-label {
			cursor: pointer;
			font-weight: 400;
			color: $black-color;
			margin-left: 10px;
			-webkit-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}

		.year-input {
			width: 60px;
			border: none;
			margin: 0px 10px;

			.form-control:focus {
				border: none !important;
			}
		}

		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none !important;
		}

		input[type='number'] {
			-moz-appearance: textfield;
			text-align: center !important;
		}
	}

	.button-wrapper {
		button {
			min-width: 150px;
		}
	}

	.container {
		min-height: inherit;
		position: relative;
		width: $full-width;
		padding: 20px 15px;
	}

	.close {
		position: absolute;
		top: 5px;
		right: 5px;
		z-index: 30;
	}
}

.load-div {
	min-height: inherit;
	position: absolute;
	width: $full-width;
}

.load-div .text-info {
	width: 70px;
	height: 70px;
	font-size: 30px;
}

.contact-frame {
	background-color: $tangerine;
	width: 100%;
	height: 11px;
	margin: 0;
}

.pac-item {
	border: none !important;
	padding: 3px 20px;

	&:hover {
		background-color: rgba(217, 217, 217, 0.37);
		opacity: 0.8;
		border-radius: 4px;
		transition: all 0.2s linear;
	}
}
.pac-container {
	border: 0.5px solid #aeaeae;
	padding: 18px 9px 5px 5px;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 8px;
}
.pac-container.pac-logo::after,
.pac-icon-marker {
	display: none !important;
}
