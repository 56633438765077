@font-face {
	font-family: 'Battambang';
	font-display: auto;
	font-weight: 100;
	src: url('../fonts/Battambang-Thin.ttf');
	font-display: swap;
}
@font-face {
	font-family: 'Battambang';
	font-display: auto;
	font-weight: 300;
	src: url('../fonts/Battambang-Light.ttf');
	font-display: swap;
}

@font-face {
	font-family: 'Battambang';
	font-display: auto;
	font-weight: 400;
	src: url('../fonts/Battambang-Regular.ttf');
	font-display: swap;
}

@font-face {
	font-family: 'Battambang';
	font-display: auto;
	font-weight: 700;
	src: url('../fonts/Battambang-Bold.ttf');
	font-display: swap;
}
