@import '~assets/css/variable.scss';

.relative {
	position: relative;
}

.text-underline {
	text-decoration: underline;
}

.image-gallery-popup {
	&::-webkit-scrollbar {
		width: 10px;
		height: 18px;
		background-color: $white-color;
		border-radius: 7px;
	}

	&::-webkit-scrollbar-thumb {
		height: 6px;
		background-clip: padding-box;
		border-radius: 10px;
		background-color: $white-color;
	}

	&::-webkit-scrollbar-thumb {
		height: 6px;
		background-clip: padding-box;
		border-radius: 10px;
		background-color: #cecece;
	}

	&::-webkit-scrollbar-corner {
		background-color: transparent;
	}
}

.object-detail {
	margin: 0 auto;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		margin: 0;
	}

	.row {
		margin: 0;
	}

	.disable-object-container {
		position: fixed;
		width: 100vw;
		height: 100vh;
		margin: 0 auto;
		left: 0;
		background: #0000007a;
		color: white;
		text-align: center;
		padding-top: 10%;
		font-size: 25px;
		z-index: 1016;
		top: 0;
	}

	.object-image-gallery {
		padding-top: 25px;

		.row {
			position: relative;
		}

		.spin-img {
			margin-top: 15% !important;
			margin-bottom: 15% !important;
		}

		.button {
			position: absolute;
			top: 20px;
			left: 20px;
			height: 36px;
			width: 110px;
			background-color: $white-color;
			border: 1px solid $grey-border;
			border-radius: 18px;
			font-size: 11px;
			line-height: 34px;
			font-weight: $font-regular;
			transition: all 0.3s ease;

			&:hover {
				color: $primary-color;
				border-color: $primary-color;
			}
		}

		.column-wrapper {
			padding: 0;
		}

		.img-wrapper {
			position: relative;
			padding: 5px;
			border-radius: 5px;
			max-height: 650px;
			height: 262.5px;

			&::before {
				content: '';
				width: calc(100% - 10px);
				height: calc(100% - 10px);
				position: absolute;
				top: 5px;
				left: 5px;
				background-color: transparent;
				border-radius: 5px;
				transition: all 0.3s ease;
			}

			&:hover {
				&::before {
					background-color: rgba(255, 255, 255, 0.3);
				}
			}

			&.lg {
				height: 525px;
				max-width: 100%;

				img {
					height: 100%;
				}
			}

			img {
				width: 100%;
				-o-object-fit: cover;
				object-fit: cover;
				border-radius: 5px;
				height: 100%;
			}
		}
	}

	.object-description {
		color: $grey-light;
		padding-top: 20px;

		.column-wrapper {
			.title {
				font-size: 16px;
				font-weight: $font-bold;
				margin-bottom: 15px;
			}

			.map-icon {
				width: 20px;
				margin-right: 5px;
				margin-top: -3px;
				fill: $immozy-dark;
			}

			a {
				text-decoration: underline;
			}

			.object-status {
				font-size: 14px;

				span {
					font-size: 16px;
					font-weight: 600;
					color: $primary-color;
				}
			}

			.object-para {
				font-size: 14px;
				line-height: 21px;
				overflow: hidden;
			}

			.read-more {
				color: $primary-color;
				font-weight: $font-bold;
				cursor: pointer;
			}
		}

		.description-card {
			border-radius: 20px;
			box-shadow: 0px 1px 14px 2px rgba(0, 0, 0, 0.15);
			margin-top: -100px;
			background-color: $white-color;

			.card-item {
				padding: 25px;
				box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.1);

				&:first-child {
					border-top-left-radius: 20px;
					border-top-right-radius: 20px;
				}

				&:last-child {
					box-shadow: none;
				}

				.button {
					max-width: 207px;
					width: 100%;
					margin: 10px auto;
					padding: 0 15px;
					background-color: $primary-color;
					border-radius: 20px;
					color: $white-color;
					font-size: 14px;
					height: 40px;
					line-height: 40px;
					transition: all 0.4s ease;
					font-weight: 600;

					&:hover {
						background-color: $hover-red;
					}

					.contact-svg {
						fill: $white-color;
						width: 25px;
						margin-right: 5px;
					}

					&:disabled {
						opacity: 0.6;
						cursor: not-allowed;

						&:hover {
							background-color: $primary-color;
						}
					}
				}
				.contact-btn {
					background-color: $yellow-sea;
					border-radius: 20px;
					color: #fff;
					font-size: 14px;
					font-weight: 600;
					height: 40px;
					line-height: 40px;
					margin: 10px auto;
					max-width: 207px;
					padding: 0 15px;
					transition: all 0.4s ease;
					width: 100%;
				}
			}

			.card-title {
				width: calc(100% - 45px);
				font-size: 18px;
				font-weight: 600;
			}

			.object-items {
				font-size: 14px;

				.legal-text {
					font-size: 12px;
					font-weight: bold;
				}

				h2 {
					font-size: 16px;
					font-weight: 600;

					&.price {
						font-size: 25px;
						text-align: left !important;
						white-space: nowrap;
					}

					&.realtor-name {
						font-size: 14px;
					}
				}

				h6 {
					font-size: 14px;
					font-weight: 600;
					color: $grey-border;

					&.realtor-name {
						font-size: 12px;
					}
				}

				.fa-check {
					color: $primary-color;
					font-size: 18px;
					margin-right: 5px;
				}
			}
		}
	}

	.tab-view {
		width: 100%;
		.nav-tabs {
			border: none !important;

			.nav-link {
				border-top-left-radius: 15px;
				border-top-right-radius: 15px;
				padding: 8px 15px;
				background-color: $bg-white;
				margin-right: 8px;
				color: $grey-light;
				font-weight: 600;
				font-size: 14px;
				transition: all 0.4s ease;

				&:last-child {
					margin-right: 0;
				}

				&:hover,
				&.active {
					color: $primary-color;
					background-color: $white-color;
					border-bottom: 1px solid $white-color !important;

					svg {
						fill: $primary-color;
					}
				}
			}
		}

		.tab-content {
			border: 1px solid #e9ecef;
			height: calc(100% - 40px);
			border-radius: 20px;
			border-top-left-radius: 0;
			padding: 20px;
			min-height: 493px;
			width: 100%;
			max-width: 64.66%;

			.detail-item {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}

				p {
					font-size: 14px;
					color: $immozy-dark;

					span {
						font-weight: 600;
					}

					.color {
						height: 15px;
						width: 15px;
						border-radius: 4px;
						display: inline-block;
						margin-right: 10px;
					}
				}
			}
		}
	}

	.extra-section-wrapper {
		margin: 20px 0 0 15px;
	}
	.extra-section {
		h2 {
			font-size: 14px;
			color: $primary-color;
			font-weight: 600;
			margin-bottom: 25px;
		}

		p,
		button {
			width: -webkit-fit-content;
			width: -moz-fit-content;
			width: fit-content;
			color: $grey-light;
			height: 32px;
			background-color: #e2e6f1;
			border-radius: 9px;
			padding: 5px 15px;
			margin-bottom: 5px;
			font-weight: 600;
			font-size: 14px;
		}
	}

	.map-location {
		.label-text {
			color: $primary-color;
			font-size: 16px;
			font-weight: 600;
			margin-bottom: 10px;
		}

		h3 {
			font-size: 14px;
			font-weight: 600;
			color: $grey-light;
		}

		.map-view {
			width: 100%;
			height: 470px;

			.empty-map {
				background-color: lightgray;
				height: 100%;
			}
		}
	}

	.location-info {
		.label-text {
			color: $primary-color;
			font-size: 16px;
			font-weight: 600;
			margin-top: 31px;
			margin-bottom: 5px;
		}
		.title {
			color: $primary-color;
			font-size: 14px;
			font-weight: 600;
		}

		a {
			font-size: 14px;
			text-decoration: underline;
		}

		.detail-item {
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}

			p {
				font-size: 15px;
				color: $immozy-dark;

				// &:last-child {
				// 	font-weight: 600;
				// }
			}
			.property-address {
				font-size: 15px;
				color: $immozy-dark;
			}
		}
	}

	.model-wrapper {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba(0, 0, 0, 0.25);
		transition: all 0.4s ease;
		z-index: -1;
		opacity: 0;
		padding: 80px 50px 50px;
		margin: 0;
		border: 1px solid grey;

		&.show {
			z-index: 100;
			opacity: 1;
		}

		.image-gallery-popup {
			width: $full-width;
			background-color: $white-color;
			border-radius: 23px;
			height: 85vh;
			max-height: 800px;
			margin: 0 !important;

			.custom-filters {
				position: absolute;
				z-index: 1;
				top: 60px;
				right: 0px;
				height: 53px;
				transition: all 0.4s ease;
				border-bottom-left-radius: 27px;
				border-top-left-radius: 27px;

				&.hide-filters {
					.map-filter {
						display: none;
					}
				}

				.e {
					box-shadow: none;
				}

				.MuiGrid-container {
					display: flex;
					flex-wrap: wrap;
					box-sizing: border-box;
					height: 53px;
					border-bottom-left-radius: 27px;
					border-top-left-radius: 27px;
					align-content: center;
				}

				.custom-checkbox {
					padding-left: 28px;
					min-height: auto !important;
				}

				.custom-control-label {
					padding-top: 4px;

					&::before {
						left: -28px;
						top: 1px;
						height: 22px;
						width: 22px;
						border-radius: 6px !important;
						border: 1px solid #979797;
						box-shadow: none !important;
					}

					&::after {
						left: -28px;
						top: 1px;
						height: 22px;
						width: 22px;
						border-radius: 6px !important;
					}
				}

				.map-icon {
					width: 23px;
					margin-right: 3px;
					fill: $grey-light;
					margin-top: -5px;
				}
			}

			&.one-image-slider {
				.image-gallery-image {
					width: auto;
					max-height: 675px;
					margin-top: 20px;
					border-radius: 5px;
					height: calc(100vh - 82px);
				}
			}

			.contact-popup-wrapper {
				width: 100%;
				max-width: 600px;
				margin: 0 auto;
				padding: 20px 20px;
				height: 100%;
				overflow-x: hidden;
				overflow-y: auto;
				// display: flex;
				// justify-content: center;

				.contact-popup-tittle {
					font-size: 26px;
					font-weight: $font-bold;
					color: #444;
					margin-bottom: 10px;
				}
				.contact-popup-name {
					font-size: 20px;
					color: $bunting;
				}
				.info-btn {
					position: relative;

					.tool-tip {
						position: absolute;
						bottom: 48px;
						background: rgba(0, 0, 0, 0.75);
						visibility: hidden;
						opacity: 0;
						transition: 0.2s all ease-in-out;
						pointer-events: none;
						width: 200px; // Adjust this value as needed
						p {
							padding: 6px;
							font-size: 14px;
							line-height: 1.3;
							color: $white-color;
						}

						&::after {
							position: absolute;
							bottom: -6px;
							content: '';
							right: calc(50% - 6px);
							border-top: 6px solid rgba(0, 0, 0, 0.75);
							border-right: 6px solid transparent;
							border-bottom: 0;
							border-left: 6px solid transparent;
						}
					}

					&:hover {
						.tool-tip {
							bottom: 28px;
							opacity: 1;
							visibility: visible;
						}
					}
				}

				.contactPopup-form {
					width: 100%;
					.frist-last-name-input {
						width: 50%;
					}
					.contactPopup-form-input-wrapper {
						margin-bottom: 10px;
						.contactPopup-form-label {
							font-size: 16px;
							color: #444;
							font-weight: $font-bold;
							line-height: 22px;
							margin-bottom: 8px;
						}
						input,
						textarea {
							border: 1px solid $grey-5;
							border-radius: 6px;
							box-shadow: none;
							color: rgba(0, 0, 0, 0.85);
							font-size: 15px;
							font-weight: 400;
							line-height: 24px;
							// padding: 12px;
							height: 36px;
							width: 100%;
							&:focus-visible {
								outline: none;
								box-shadow: none;
							}
						}
						.error-border {
							border: 1px solid red !important;
						}
						.input-redio-btn {
							height: 20px;
							width: 20px;
							margin-right: 15px;
						}

						.field-wind {
							color: rgba(0, 0, 0, 0.85);
							font-size: 15px;
							font-weight: 400;
							line-height: 24px;
						}

						.react-tel-input {
							.flag-dropdown {
								background-color: $white-color;
								border: 1px solid $grey-5;
							}
						}
					}

					.error-massage {
						height: 23px;
						margin-top: 20px;
						p {
							text-align: center;
							font-size: 18px;
							font-weight: $font-regular;
							color: $red;
						}
					}
					.contactPopup-button {
						width: 160px;
					}
				}
				.contectPopup-check-box {
					padding: 0px;
					.confirm-container {
						p {
							color: rgba(0, 0, 0, 0.5);
							font-size: 12px;
							line-height: 16px;
							padding-left: 10px;
							a {
								color: $tangerine;
							}
						}
						.checkmark {
							height: 20px;
							width: 20px;
						}
						.checkmark::after {
							width: 6px;
							height: 11px;
							left: 6px;
						}
					}
				}
			}
		}
		input[type='radio'] {
			/* Add if not using autoprefixer */
			-webkit-appearance: none;
			/* Remove most all native input styles */
			appearance: none;
			/* For iOS < 15 */
			background-color: var(--form-background);
			/* Not removed via appearance */
			margin: 0;

			font: inherit;
			color: currentColor;
			width: 12px;
			height: 12px;
			border: 2px solid currentColor;
			border-radius: 50%;
			transform: translateY(-0.075em);

			display: grid;
			place-content: center;
		}

		input[type='radio']::before {
			content: '';
			width: 11px;
			height: 11px;
			border-radius: 50%;
			transform: scale(0);
			transition: 120ms transform ease-in-out;
			box-shadow: inset 1em 1em var(--form-control-color);
			/* Windows High Contrast Mode */
			background-color: $yellow-sea;
		}

		input[type='radio']:checked::before {
			transform: scale(1);
		}

		input[type='radio']:checked {
			outline: max(2px, 2px) solid $yellow-sea;
		}

		.contact-popup {
			width: 100%;
			max-width: 700px;
			height: 90vh;
			max-height: 860px;
			padding: 10px 0;
			margin: 0 auto !important;
		}
		.image-gallery-thumbnails-wrapper {
			width: 60% !important;
			margin: 0 auto !important;
		}

		.close-btn-div {
			z-index: 11;
			text-align: center;
		}

		.image-gallery {
			height: 100%;

			.image-gallery-content {
				height: 100%;

				.image-gallery-slide-wrapper {
					height: calc(100% - 100px);

					.image-gallery-icon {
						border: 1px solid lightgray;
						border-radius: 50%;
						filter: none;

						.image-gallery-left-nav .image-gallery-svg,
						.image-gallery-right-nav .image-gallery-svg {
							color: #a4a4a4;
						}

						&:hover {
							border: 1px solid lightgray !important;
							filter: drop-shadow(0 2px 2px #cececc) !important;

							.image-gallery-left-nav .image-gallery-svg,
							.image-gallery-right-nav .image-gallery-svg {
								color: $primary-color !important;
							}
						}
					}

					.image-gallery-swipe {
						height: 100%;

						.image-gallery-slides {
							height: 100%;

							.image-gallery-slide {
								height: 100%;
								overflow: hidden;

								div {
									height: 100%;
								}

								.image-gallery-image {
									height: 100%;
									width: auto;
									max-height: 675px;
									margin-top: 20px;
									border-radius: 5px;
								}
							}
						}
					}
				}
			}

			.image-gallery-left-nav,
			.image-gallery-right-nav {
				padding: 0;
				margin: 50px 5%;
			}

			.image-gallery-thumbnail {
				border-radius: 5px;
				overflow: hidden;
				border: 2px solid transparent;
				width: 115px !important;

				&:hover,
				&:focus,
				&.active {
					border: 2px solid $primary-color;
				}
			}
		}
	}
	.contact-popup-wrapper {
		padding: 20px 50px 50px 50px;
	}
	.contact-login-popup {
		width: 558px;
		padding: 65px 60px 30px;
		.close-icon {
			top: 23px;
			right: 23px;
		}
		P {
			font-size: 15px;
			line-height: 24px;
			color: $bunting;
			margin-bottom: 20px;
		}
		button {
			font-size: 12px;
			text-transform: uppercase;
			width: 180px;
			line-height: 15px;
		}
	}
	.object-detail-contact {
		background-color: $tangerine;
		border-radius: 59px;
		margin: 80px auto;
		max-width: 1200px;
		display: flex;
		align-items: center;
		height: 118px;
		padding: 0 50px;
		.contact-text {
			p {
				font-size: 16px;
				font-weight: 500;
				line-height: 20px;
				color: $white-color;
			}
		}
		.contact-btn-div {
			background-color: $yellow-sea;
			border-radius: 60px;
			height: 60px;
			margin: 0 0 0 15px;
			max-width: 207px;
			padding: 0 30px;
			width: 100%;
			text-align: center;

			.contact-btn {
				color: #fff;
				font-size: 15px;
				font-weight: 600;
				line-height: 60px;
			}
		}
	}
	.object-user-detail-div {
		padding-left: 15px;
		h6 {
			font-size: 14px;
			color: $immozy-dark;
			font-weight: $font-bold;
			line-height: 24px;
			margin-right: 5px;
			margin-bottom: 15px;
		}
		p {
			font-size: 14px;
			color: $immozy-dark;
			line-height: 24px;
			margin-bottom: 15px;
		}

		.information-div {
			margin-bottom: 10px;
			p {
				margin-bottom: 0px;
			}
			h6 {
				margin-bottom: 0px;
			}
			.imprint-link {
				a {
					&:hover::after {
						content: '\01F517';
						margin-left: 5px;
					}
				}
			}
		}
	}
}

.gm-control-active {
	position: relative;
	background-color: $white-color !important;
	border: 1px solid $grey-border !important;
	box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.28);

	&::before {
		position: absolute;
		content: '';
		background-size: contain;
		height: 17px;
		width: 17px;
		top: 12px;
		left: 11px;
		color: $white-color;
	}

	&:first-child {
		border-top-left-radius: 30px;
		border-top-right-radius: 30px;
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
		border-bottom: none !important;

		&::before {
			background-image: url('../../assets/images/plus.png');
		}
	}

	&:last-child {
		border-bottom-left-radius: 30px;
		border-bottom-right-radius: 30px;
		border-top-left-radius: 0 !important;
		border-top-right-radius: 0 !important;

		&::before {
			background-image: url('../../assets/images/minus-button.png');
			top: 10px;
		}
	}

	img {
		display: none !important;
	}
}

.object-data-svg {
	fill: $immozy-dark;
	width: 23px;
	margin-right: 5px;
}

.close-btn-div {
	position: absolute;
	height: 26px;
	width: 26px;
	right: 15px;
	top: 15px;
	background-repeat: no-repeat;
	cursor: pointer;
	padding: 5px;
	border-radius: 50%;
	border: 1px solid #979797;
	line-height: 10px;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
	transition: 0.3s ease;
	z-index: 5;
	text-align: center;

	svg {
		width: 12px;
		transition: 0.3s ease;
		fill: $grey-light;
		font-weight: $font-bold;
	}

	&:hover {
		box-shadow: 0px 0 4px 1px rgba(0, 0, 0, 0.1);

		svg {
			fill: $primary-color;
		}
	}

	&.active {
		background-color: $primary-color;
		border-color: $primary-color;
		box-shadow: none !important;

		svg {
			fill: $white-color;
		}

		&:hover {
			svg {
				fill: $white-color;
			}
		}
	}
}
.mobile-responsive-object {
	padding: 0 10px;
	.object-title {
		padding: 20px 0;
		h1 {
			font-size: 20px;
			line-height: 25px;
			font-weight: $font-bold;
			color: $bunting;
		}
	}
	.object-detail-image {
		background-color: #e9e4e4;
		padding: 20px 5px;
		.swiper-wrapper {
			padding-bottom: 30px;
			.swiper-slide {
				display: flex;
				align-items: center;
				justify-content: center;
				.image-wrapper {
					width: 314px;
					height: 298px;
					img {
						height: 100%;
						width: 100%;
						object-fit: cover;
						object-position: center;
						border-radius: 15px;
						box-shadow: 0 3px 20px -1px #cecece;
					}
				}
			}
		}
	}
	.object-details {
		box-shadow: 0 0 7px 0 #cecece;
		padding: 20px 0;
		margin-bottom: 20px;
		h1 {
			font-size: 16px;
			line-height: 25px;
			font-weight: $font-bold;
			color: $bunting;
		}
		P {
			font-size: 16px;
			line-height: 25px;
			color: $bunting;
		}
		.price-text {
			font-size: 36px;
			line-height: 40px;
			font-weight: $font-bold;
		}
	}
}
