@import '../../assets/css/variable.scss';

.common-hr {
	background-color: $bunting;
	height: 30px;
	margin: 0px;
	opacity: 1;
}
.teaser-wrapper {
	width: 100%;
	height: 400px;

	.teaser-image-wrapper {
		background: url('../../assets/images/teaser-image.png') no-repeat;
		width: 100%;
		height: 100%;
		background-size: cover;
		.teaser-title-wrapper {
			.teaser-title {
				font-size: 64px;
				font-weight: $font-regular;
				color: $white-color;
				line-height: 60px;
				margin-top: 78px;
				margin-bottom: 20px;
			}
		}
		.teaser-subtitle-wrapper {
			width: 100%;
			max-width: 446px;
			min-height: 120px;
			background: linear-gradient(180deg, #ffb000 0%, #f38300 100%);
			border-radius: 8px;
			margin: 0 auto 20px;
			padding: 20px;
			.teaser-subtitle {
				font-size: 24px;
				font-weight: $font-regular;
				line-height: 23px;
				color: $white-color;
			}
		}
	}
}
.location-section {
	padding-bottom: 40px;
}
.home-container {
	.filter-section {
		margin-top: -30px;
		padding-bottom: 40px;

		.filter-container {
			width: 100%;
			max-width: 832px;
			background: $white-color;
			box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
			border-radius: 12px;
			margin: 0 auto;
			padding: 15px;

			.city-detail-wrapper {
				.location-search {
					font-weight: $font-regular;
					font-size: 16px;
					line-height: 23px;
					color: $bunting;
					width: 100%;
					height: 45px;
					border: 0.5px solid $dark-gray;
					border-top-left-radius: 6px;
					border-bottom-left-radius: 6px;
					// padding-left: 18px;
					// overflow: hidden;
					// text-overflow: ellipsis;
					&:focus-visible {
						outline: none;
					}
					// .wrapper {
					//   .sc-hLseeU > input {
					//     width: 221px;
					//     overflow: hidden;
					//     text-overflow: ellipsis;
					//   }
					// }

					.wrapper > div:nth-child(2) {
						top: 44px;
						background-color: #fff;
						border-radius: 8px;
						box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
						margin-bottom: 8px;
						position: absolute;
						width: 300px;
						z-index: 3;
						box-sizing: border-box;
						border: 0.5px solid $dark-gray;
						padding: 6px 9px 0 5px;
						overflow: hidden;

						ul {
							height: 200px;
							overflow: auto;
							li {
								color: $bunting;
								font-size: 14px;
								font-weight: 400;
								padding: 9px 10px;
							}
						}
					}
				}
				.city-name {
					width: 200px;
					outline: none;
					height: 45px;

					.search-icon {
						display: none;
					}
				}
			}

			.search-btn {
				background: $yellow-sea;
				border: 0.5px solid $dark-gray;
				border-radius: 6px;
				width: 100%;
				max-width: 187px;
				min-height: 38px;
				font-size: 15px;
				font-weight: $font-bold;
				line-height: 23px;
				color: $white-color;
				box-shadow: none;
			}
		}
	}
	.location-tittle {
		font-size: 35px;
		font-weight: $font-regular;
		line-height: 60px;
		color: $bunting;
	}

	.top-property-object {
		background-color: $light-red;
		.property-wrapper {
			padding: 34px 0 54px;
			.property-details {
				min-height: 296px;
				color: transparent;
			}
		}
	}
	.Services {
		padding-top: 25px;
		.service-wrapper {
			padding: 34px 0;
			.service-icons {
				width: 100%;
				max-width: 74px;
				height: 78px;
			}
			.title {
				font-size: 24px;
				font-weight: $font-regular;
				line-height: 60px;
				color: #000000;
			}
			.subtitle {
				font-size: 16px;
				font-weight: $font-light;
				line-height: 60px;
				color: #000000;
				text-decoration: underline;
			}
		}
		.services-hover-effect {
			padding: 10px;
			width: 240px;
			&:hover {
				border-radius: 12px;
				background-color: rgba(209, 209, 209, 0.15);
				box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
				transition: background-color 300ms linear;
				transition: box-shadow 300ms linear;
			}
		}
	}
}
.register-section {
	background-color: $tangerine;
	padding: 20px 0;
	.register-text {
		font-size: 20px;
		line-height: 25px;
		color: $white-color;
		margin-bottom: 20px;
	}
	.register-email {
		padding: 8px 18px;
		background-color: $white-color;
		border-radius: 4px;
		outline: none;
		border: none;
		width: 100%;
		max-width: 390px;
	}
	.register-email-error {
		border: 0.5px solid red;
	}
	.send-button {
		background-color: $yellow-sea;
		border: 0.5px solid $dark-gray;
		border-radius: 6px;
		font-weight: $font-bold;
		font-size: 15px;
		width: 100%;
		max-width: 180px;
		min-height: 37px;
		margin-left: 15px;
		border: none;
		color: #fff;
	}
}
.location-wrapper {
	padding: 0 10px;
	.location-text {
		width: 100%;
		max-width: 650px;
		margin: 0 auto;
		padding: 30px 0;
		.location-tittle {
			font-size: 35px;
			line-height: 60px;
			color: $bunting;
		}
		.location-subtittle {
			font-size: 18px;
			font-weight: $font-regular;
			line-height: 26px;
			color: $bunting;
		}
	}
	.city-name-div {
		display: grid;
		grid-template-columns: auto auto auto;
		column-gap: 100px;
		.map-wrapper {
			width: 100%;
			max-width: 230px;
			height: auto;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}

		.city-name {
			display: grid;
			grid-template-columns: auto auto;
			column-gap: 100px;
			a {
				font-size: 20px;
				font-weight: $font-regular;
				line-height: 40px;
				color: $tangerine;
			}
		}
	}
}

.landing-page-wrapper {
	width: 100%;
	margin: 0 auto;
	max-width: 1920px;

	.property-card {
		.swiper-wrapper-div {
			.swiper-slide {
				min-width: 341px;
			}
		}
	}
}
.dropdown-arrow {
	font-size: 24px !important;
	color: $yellow-sea;
}
.rotate--180 {
	rotate: 180deg;
}

.user-cost-space-wrapper {
	margin: 10px 0;
	.cost-filter {
		width: 200px;
		.cost-button {
			width: 45px;
			height: 25px;
			background-color: $white-color;
			font-weight: bold;
			font-size: 12px;
			line-height: 23px;
			color: $bunting;
			border: none;
			box-shadow: none;
			// padding: 0 0 0 5px;
			margin: 0 5px;
			outline: none;

			&:hover {
				background-color: $white-color;
				color: $bunting !important;
			}
			&:active {
				background-color: $white-color !important;
				color: $bunting !important;
				border: none !important;
				&:focus {
					box-shadow: none !important;
					outline: none;
				}
			}
			.user-input {
				width: 40px;
				height: unset;
				padding: 0 2px;
				text-decoration: none;
			}
		}
		.aria-text {
			text-decoration: underline;
			padding-left: 34px;
			&:focus {
				outline: none;
				// border: none;
			}
		}
	}
	input[type='number']::-webkit-inner-spin-button,
	input[type='number']::-webkit-outer-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		margin: 0;
	}
	.user-input-wrapper {
		font-weight: $font-regular;
		font-size: 12px;
		line-height: 23px;
		color: $bunting;
		font-family: 'Battambang';
		border: none;
		text-align: center;
		&:focus {
			outline: none;
		}
		&::placeholder {
			font-weight: $font-regular;
			font-size: 12px;
			line-height: 23px;
			color: $bunting;
			font-family: 'Battambang';
		}
	}
}
.romm-data-wrapper {
	width: 200px;

	p {
		font-size: 12px;
		line-height: 23px;
	}
}

.filter-value {
	font-weight: $font-regular;
	line-height: 23px;
	font-size: 14px;
	width: 100%;
	max-width: 100px;
	margin: 0 5px;
	border: none;

	&:focus-visible {
		outline: none;
	}
	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

.room-dropdown .react-select__control {
	color: $bunting !important;
	border: none !important;
	font-size: 12px;

	.react-select__placeholder,
	.react-select__single-value {
		font-size: 12px;
	}
}
