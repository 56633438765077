.section-width {
	width: 100%;
	max-width: 908px;
	margin: 0 auto;
}
.public-page-font {
	h1 {
		font-size: 20px;
		line-height: 30px;
		font-weight: $font-bold;
		color: $bunting;
	}
	h4 {
		font-size: 20px;
		line-height: 30px;
		font-weight: $font-bold;
		color: $bunting;
	}
	h3 {
		font-size: 20px;
		line-height: 30px;
		font-weight: $font-bold;
		color: $bunting;
	}
	p {
		font-size: 15px;
		color: $bunting;
		line-height: 26px;
	}
	ol {
		margin-left: 40px;
		li {
			list-style-type: decimal;
			list-style-position: outside;
			display: list-item;
			font-size: 15px;
			line-height: 26px;
		}
	}
}

.information-section {
	margin: 30px auto 20px;
	h1 {
		font-size: 25px;
		line-height: 32px;
		font-weight: $font-bold;
		color: $bunting;
		margin-bottom: 15px;
	}
	p {
		font-size: 18px;
		line-height: 26px;
		color: $bunting;
		// a {
		// 	color: $bunting;
		// }
	}
}
.description-section {
	width: 100%;
	margin: 65px auto;

	.description-tittle {
		margin-bottom: 50px;
		.description-subtittle {
			margin-bottom: 30px;
		}
		h4 {
			margin-bottom: 10px;
		}
		h3 {
			margin-bottom: 10px;
		}
		p {
			margin-bottom: 10px;

			a {
				font-size: 15px;
				color: $bunting;
				text-decoration: underline;
				word-break: break-all;
			}
		}
	}
}

.title-image-wrapper {
	width: 100%;
	height: 475px;
	.tittle-img {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top;
	}
}
.common-page-tittle-div {
	background-color: $tangerine;
	width: 100%;
	max-width: 500px;
	height: 115px;
	bottom: -51px;
	h1 {
		font-size: 40px;
		line-height: 40px;
		font-weight: $font-bold;
		color: $white-color;
		white-space: pre-line;
	}
}
.common-title-background {
	width: 100%;
	height: 52px;
	background-color: $tangerine;
}

///about-us page
.about-us-wrapper {
	.about-us-tittle {
		width: 100%;
		height: 52px;
		background-color: $tangerine;
		p {
			font-size: 24px;
			color: #ffff;
		}
	}
	.about-us-text {
		padding: 28px 0 28px 0;

		.text-wrapper {
			width: 100%;
			max-width: 890px;
			margin: 0 auto;
			h3 {
				margin: 20px 0;
				font-size: 25px;
				line-height: 32px;
				text-align: center;
			}
			p {
				margin-bottom: 30px;
				font-size: 18px;
				line-height: 26px;
				text-align: center;
			}
		}
	}
}
.market-report-section {
	.title-text {
		font-size: 25px;
		line-height: 32px;
		font-weight: 700;
		color: #2c344e;
		margin-bottom: 15px;
	}
	.certificate-cards {
		flex-wrap: wrap;
		gap: 20px;
	}
}

/// energy-certificate
.energy-certificate-section {
	.bg-gray {
		background-color: #ededed;
	}

	.energy-bottom-padding-30 {
		padding-bottom: 40px;
	}
	.energy-bottom-padding-40 {
		padding-bottom: 40px;
	}
	.energy-certificate-div {
		padding: 20px 0;
		h1 {
			color: $white-color;
			margin-bottom: 15px;
			font-size: 25px;
			line-height: 32px;
			text-align: center;
		}
		p {
			font-size: 18px;
			line-height: 26px;
			color: $white-color;
			text-align: center;
		}
	}
	.background-color-bunting {
		background-color: $bunting;
	}
}

.height-unset {
	height: unset !important;
}

.certificate-needed-section {
	margin: 30px auto 90px;
	h1 {
		margin-bottom: 15px;
		text-align: center;
	}
	p {
		text-align: center;
	}

	.certificate-cards {
		margin-top: 40px;
		.certificate-needed-card {
			width: 100%;
			max-width: 438px;
			height: 240px;
			border-radius: 12px;
			box-shadow: 8px 8px 22px 0px rgba(0, 0, 0, 0.27);
			padding: 28px 26px;
		}
	}
}

.padding-top-bottom-40 {
	padding: 40px 0;
}

.energy-image {
	width: 723px;
}
.certificate-card-wrapper {
	margin-top: 40px;
	.certificate-needed-card {
		background-color: $white-color;
		column-gap: 55px;
		.card-image {
			width: 50%;
			width: 400px;
			height: 250px;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
		&:last-child {
			.card-image {
				order: 2;
			}
		}

		.card-description {
			width: 50%;

			h1,
			p {
				text-align: left !important;
			}
		}
		width: 100%;
		border-radius: 12px;
		box-shadow: 8px 8px 22px 0px rgba(0, 0, 0, 0.27);
		padding: 28px 26px;
	}
}

///help page
.help-section {
	.description-section {
		.description-tittle {
			h3 {
				margin-top: 25px;
				font-size: 25px;
				line-height: 32px;
				text-align: center;
			}
			p {
				font-size: 18px;
				line-height: 26px;
				text-align: center;
			}
		}
	}
}

//price-advice-page
.price-advice-section {
	.view-of-property-section {
		background-color: #ededed;
		h1 {
			font-size: 26px;
			line-height: 32px;
			color: $bunting;
			font-weight: $font-bold;
			text-align: center;
			padding: 44px 42px;
		}
		.property-view {
			margin: 0px 77px;
			gap: 73px;
			.property-div {
				width: 340px;
				height: 460px;
				img {
					width: 100%;
					height: 100%;
					max-height: 340px;
					object-fit: cover;
					object-position: center;
				}
				h3 {
					font-size: 18px;
					line-height: 26px;
					color: $bunting;
					font-weight: $font-bold;
					text-align: center;
					margin: 15px 0px 30px;
				}
			}
		}
	}
	.advised-section {
		padding: 44px 0 35px;
	}
}

//our team page
.our-team-wrapper {
	.features-section {
		background-color: #edebe8;
		padding: 27px 10px;
		.features {
			display: grid;
			grid-template-columns: auto auto auto auto;
			gap: 50px;
			.feature-div {
				width: 100%;
				max-width: 230px;
				h3 {
					color: $tangerine;
					margin-top: 12px;
				}
			}
		}
	}
	.cooperation-partner-section {
		margin: 48px auto 20px;

		.cooperation-partner-image {
			width: 100%;
			max-width: 524px;
			height: 351px;
			margin: 0 auto;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
	}
	.team-about-us-section {
		background-color: $light-red;
		padding: 60px 10px;
		.card-wrapper {
			display: grid;
			grid-template-columns: auto auto auto;
			gap: 40px;
			justify-content: center;
			.about-us-card {
				width: 100%;
				max-width: 334px;
				height: 100%;
				min-height: 334px;
				background-color: $bunting;
				padding: 0 38px 38px;
				.card-title-wrapper {
					width: 100%;
					height: 47px;
					background-color: $tangerine;
					text-align: center;
					margin: 0 auto 10px;

					h1 {
						font-size: 16px;
						line-height: 20px;
						font-weight: $font-bold;
						color: $white-color;
					}
				}
				P {
					font-size: 14px;
					line-height: 20px;
					color: $white-color;
					text-align: center;
				}
			}
		}
	}
	.partner-models-section {
		.partner-models-image {
			width: 100%;
			height: auto;
			img {
				width: 100%;
				height: 100%;
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
			}
		}
		.section-width {
			max-width: 618px;
		}
		.broker-and-franchise {
			width: 100%;
			max-width: 1068px;
			margin: 0 auto;
			padding: 0 10px;
			.title-text-wrapper {
				width: 100%;
				height: 96px;
				background-color: $tangerine;
				margin-bottom: 30px;
				h1 {
					font-size: 25px;
					line-height: 32px;
					font-weight: $font-bold;
					text-align: center;
					color: $white-color;
				}
			}
			.discretion-wrapper {
				display: grid;
				grid-template-columns: auto auto auto;
				gap: 50px;
				.discretion {
					h4 {
						margin-bottom: 10px;
					}
					P {
						font-size: 14px;
						line-height: 20px;
						color: $bunting;
						margin-bottom: 10px;
						span {
							font-weight: $font-bold;
						}
					}
				}
			}
			.franchise-partner {
				grid-template-columns: auto auto;
			}
		}
	}
}

//data-protection-page
.data-protection-page {
	p {
		span {
			font-weight: $font-bold;
			margin-right: 3px;
		}
	}
}

.appraise-photos {
	background-color: $light-red;
	padding: 50px 10px;
	.card-wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		.about-us-card {
			width: 290px;
			height: 100%;
			border: 3px solid $bunting;
			overflow: hidden;
			border-radius: 17px;
			box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.27);

			.card-title-wrapper {
				background-color: $tangerine;

				h1 {
					font-size: 16px;
					font-weight: $font-bold;
					color: $white-color;
				}
			}

			.card-image {
				width: 100%;
				height: 100%;
			}
		}
	}

	.card-wrapper > div {
		margin: 6px;
	}
}
