.property-popup-wrapper {
	padding: 26px 0px 30px 0px;
	width: 700px;
	.property-popup-header {
		padding: 0 24px;
	}
	.children-div {
		height: 100%;
		max-height: 500px;
		min-height: 180px;
		padding: 0 19px 0 24px;
		margin-right: 5px;
		overflow-y: auto;

		.min-height-250 {
			margin-top: 125px;
		}
	}
	.property-header-text {
		font-size: 18px;
		font-weight: $font-bold;
		line-height: 21px;
		color: $yellow-sea;
	}
	.close-button {
		top: 23px;
		right: 24px;
	}
	.active-tab-text {
		margin-top: 22px;
		.property-name {
			font-size: 16px;
			line-height: 23px;
			color: $bunting;
			font-weight: $font-bold;
		}
		.tab-index {
			font-size: 13px;
			line-height: 23px;
			color: $bunting;
		}
	}
	.progress {
		height: auto;
		margin-top: 8px;
		margin-bottom: 8px;
		.progress-bar-color {
			background-color: $bunting;
			margin-right: 1px;
			height: 6px;
		}
		.active-popup {
			background-color: $yellow-sea;
		}
		.current-popup {
			background-color: #cf9004;
		}
	}
	.property-button-div {
		margin-top: 40px;
		.next-step-button {
			font-size: 12px;
			text-transform: uppercase;
			padding: 5px 24px;
		}
	}
	.input-field-wrapper {
		width: 260px;
	}
	.form-label {
		padding-top: 16px;
	}
	.form-asterisk {
		padding-top: 16px;
	}
	///addProperty-tab
	.add-property-tab {
		p {
			font-size: 12px;
			line-height: 16px;
			color: rgba(44, 52, 78, 0.8);
			padding: 24px 0;
			span {
				font-weight: $font-bold;
			}
		}
		h5 {
			font-size: 14px;
			font-weight: $font-regular;
			color: $bunting;
			line-height: 20px;
			padding: 0px 0px 20px;
		}

		.add-property-tab-radio-button {
			.radio-button-wrapper {
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;

				width: 134px;
				height: 114px;
				margin: 0 5px;
				padding: 0 5px;
				&:hover {
					border-radius: 12px;
					background-color: rgba(209, 209, 209, 0.15);
					box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
					transition: background-color 300ms linear;
					transition: box-shadow 300ms linear;
				}

				.checkmark {
					top: auto;
					bottom: 8px;
					left: auto;
				}
			}
			.active-radio-button {
				border-radius: 12px;
				background: rgba(209, 209, 209, 0.15);
				box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.25);
			}
		}
	}
	.generally-tab-check-box {
		width: 320px;
		margin-top: 12px;
	}
	// location-tab
	.address-field-wrapper {
		display: grid;
		grid-template-columns: auto auto;
		column-gap: 24px;
		.location-tab-select {
			.react-select__menu {
				.react-select__menu-list {
					max-height: 123px;
				}
			}
		}
	}
	.location-tab-check-box {
		margin-top: 24px;
	}
	.info-div {
		.tooltip-text {
			width: 224px;
			color: $bunting;
			border-radius: 6px;
			border: 0.5px solid $dark-gray;
			padding: 5px;
			font-size: 10px;
			line-height: 15px;
			/* Position the tooltip */
			position: absolute;
			top: -17px;
			z-index: 1;
			.close-icon {
				top: 0;
				right: 1px;
			}
		}
		&:hover {
			.tooltip-text {
				visibility: visible;
			}
		}
	}

	//detail-tab
	.detail-tab-area-field {
		display: grid;
		grid-template-columns: auto auto;
		column-gap: 24px;
		//date-picker
		.date-picker {
			padding-right: 10px;
			cursor: pointer;
		}
		.date-picker[type='date']::-webkit-calendar-picker-indicator {
			background: url('../images/date-picker-icon.png');
			object-fit: cover;
			cursor: pointer;
			background-repeat: no-repeat;
			width: 30px;
			height: 20px;
		}
	}
	.detail-tab-description {
		margin-top: 12px;
		.ql-toolbar {
			background: rgba(209, 209, 209, 0.25);
			border-radius: 6px 6px 0px 0px;
			border: 1px solid $dark-gray;
		}
		.ql-container {
			min-height: 94px;
			border-radius: 0px 0px 6px 6px;
			border: 1px solid $dark-gray;
		}
	}
	.detail-tab-image-slider {
		.swiper {
			padding: 0px 20px;
			.swiper-wrapper {
				.swiper-slide {
					margin: 20px 0 10px;
				}
			}
			.image-wrapper {
				.image {
					width: 100%;
					height: 121px;
					border-radius: 8px;
					box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);
					object-fit: cover;
				}
				.mainImage-image {
					border: 2px solid $yellow-sea;
				}
				.info {
					opacity: 0;
					transition: 0.3s;
					width: 100%;
					bottom: 0;
					padding: 8px 12px;
					border-radius: 8px;
					background: rgba(255, 255, 255, 0.8);
					.gallery-button {
						font-size: 12px;
						line-height: 16px;
						color: $yellow-sea;
					}
					.gallery-delete-button {
						color: $bunting;
						padding-top: 12px;
					}
				}
				&:hover {
					.info {
						opacity: 1;
					}
				}
			}
			.swiper-button-prev,
			.swiper-button-next {
				&::after {
					content: '';
				}
			}

			/* Style custom Swiper navigation buttons */
			.swiper-button-prev,
			.swiper-button-next {
				position: absolute;
				top: 62%;
				z-index: 10;
				width: 14px;
				height: 24px;
				cursor: pointer;
			}

			.swiper-button-prev {
				left: 0;
			}

			.swiper-button-next {
				right: 0;
			}
		}
	}
	//check-box
	.custom-checkbox-container {
		.check-box-label {
			font-size: 15px;
			line-height: 24px;
			color: $bunting;
		}
		.checkmark {
			width: 24px;
			height: 24px;
			border-radius: 6px;
			border: 0.5px solid $dark-gray;
			&::after {
				width: 8px;
				height: 12px;
				border: solid #4a4141;
				border-width: 0 2px 2px 0;
				margin: -4px 0 0 -0.5px;
			}
		}
	}
	//contact-Tab
	.contact-tab-title {
		font-size: 12px;
		line-height: 16px;
		color: rgba(44, 52, 78, 0.8);
	}
	.contact-roll-wrapper {
		margin-top: 35px;
		.user-roll-div {
			.radio-button-wrapper {
				padding: 0;
			}
			.checkmark {
				top: -22px;
				left: 40%;
			}
		}
	}
	//finance-tab
	.finance-tab {
		.finance-tab-currency-field {
			display: grid;
			grid-template-columns: auto auto;
			column-gap: 24px;

			.switch {
				position: relative;
				display: inline-block;
				width: 34px;
				height: 16px;
				margin-top: 18px;
				input {
					opacity: 0;
					width: 0;
					height: 0;
				}
			}
			.slider {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				background-color: $white-color;
				-webkit-transition: 0.4s;
				transition: 0.4s;
			}

			.slider:before {
				position: absolute;
				content: '';
				height: 15px;
				width: 16px;
				left: 0px;
				bottom: 0px;
				-webkit-transition: 0.4s;
				transition: 0.4s;
				background-color: $dark-gray;
			}

			input:checked + .slider {
				background-color: $white-color;
			}

			input:focus + .slider {
				box-shadow: 0 0 1px $grey-4;
			}

			input:checked + .slider:before {
				background-color: $yellow-sea;
				-webkit-transform: translateX(17px);
				-ms-transform: translateX(17px);
				transform: translateX(17px);
			}

			/* Rounded sliders */
			.slider.round {
				width: 34px;
				height: 16px;
				border-radius: 6px;
				border: 0.5px solid $dark-gray;
			}

			.slider.round:before {
				border-radius: 6px;
			}
			.commission-option {
				right: 0;
				background-color: transparent;
				border-left: 0.5px solid $dark-gray;
				border-top: none;
				border-right: none;
				border-bottom: none;
				height: 100%;
				padding: 3px 0px 3px 10px;
				margin-right: 10px;
				font-size: 15px;
				color: $bunting;
			}
			.commission-div {
				width: 245px;
			}
		}
		.confirm-wrapper {
			padding: 40px 0 0;
			.confirm-container {
				p {
					font-size: 12px;
				}
			}
		}
	}
	.disabled-section {
		.react-select__control--is-disabled {
			background-color: #d9d9d9;
			.react-select__value-container {
				.react-select__single-value--is-disabled {
					color: $black-color;
					opacity: 1;
				}
			}
			.react-select__indicators {
				display: none;
			}
		}
	}
	.react-select__control {
		.react-select__value-container {
			.react-select__placeholder {
				color: #d1d1d1;
			}
		}
	}
	.react-select__menu {
		.react-select__menu-list {
			.react-select__option {
				padding: 6px 20px;
			}
		}
	}
}

///upload-pdf
.document-div {
	padding-top: 16px;
	.pictures-section,
	.pdf-section {
		input[type='file'] {
			padding: 3px;
		}
		display: flex;
		align-items: center;
		width: 50%;
		justify-content: space-between;
		.document-label-placeholder {
			font-size: 12px;
			line-height: 24px;
			color: #989898;
			padding: 16px 12px 0 0;
			cursor: pointer;
		}
		.document-label {
			color: $bunting;
		}
		.upload-svg {
			padding-top: 16px;
			&:hover {
				.image-note-text {
					display: block;
				}
			}
			.image-note-text {
				position: absolute;
				right: 0;
				top: 10px;
				display: none;
				font-size: 10px;
				line-height: 10px;
				color: $bunting;
				padding: 9px 12px;
				border-radius: 6px;
				border: 1px solid $dark-gray;
				max-width: 264px;
			}
		}
		.form-check-label {
			font-weight: $font-regular;
			font-size: 15px;
			line-height: 23px;
			color: $bunting;
		}
		.form-control {
			font-size: 12px;
			line-height: 29px;
			cursor: pointer;
			color: $bunting;
			border: none;
			width: 126px;
			&::-webkit-file-upload-button {
				display: none;
			}
			&::file-selector-button {
				display: none;
			}
			&:focus {
				outline: none;
				box-shadow: none;
			}
			&:disabled {
				background-color: $white-color;
			}
		}
		.spiner {
			width: 22px !important;
			height: 22px !important;
		}
		.upload-image {
			height: auto;
			border: none;
			padding: 0;
		}
		.selected-images {
			margin-left: 5px;
			flex-wrap: wrap;
		}
		.line-height--16 {
			line-height: 16px !important;
		}
	}
	.picture-wrapper {
		.pictures-note-div {
			padding-top: 16px;
			.note-text {
				font-size: 10px;
				line-height: 10px;
				color: $bunting;
				padding: 9px 12px;
				border-radius: 6px;
				border: 1px solid $dark-gray;
				max-width: unset;
			}
			.note-error {
				color: $red;
				border: 1px solid $red;
			}
			.submit-pictures-btn {
				margin-left: 6px;
			}
		}
		.attachments-note {
			span {
				display: inline-block;
				vertical-align: bottom;
			}
			.spiner {
				width: 16px;
				height: 16px;
				margin: 0;
				position: absolute;
				right: 50px;
				border: 2px solid currentcolor;
			}
		}
	}
}

//cancel-popup-button
.cancel-popup-button {
	margin-top: 45px;
	font-size: 12px;
	color: rgba(44, 52, 78, 0.8);
	background-color: transparent;
	width: 180px;
	&:hover {
		background-color: #ffae001f !important;
	}
}
.empty-div {
	height: 88px;
}
