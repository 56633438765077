// @import "../../../assets/css/variable.scss";

.form-label {
	font-size: 15px;
	line-height: 23px;
	font-weight: $font-regular;
	color: $bunting;
	padding-top: 12px;
}
.form-asterisk {
	font-size: 15px;
	line-height: 23px;
	font-weight: $font-regular;
	color: $red;
	padding-top: 12px;
}
.form-input {
	width: 100%;
	border-radius: 6px;
	border: 0.5px solid $dark-gray;
	background: $white-color;
	padding: 5px 10px;
	font-size: 15px;
	font-weight: $font-regular;
	line-height: 15px;
	color: $bunting;
	&:focus-visible {
		outline: none;
	}
}
.form-input[type='number']::-webkit-inner-spin-button,
.form-input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	margin: 0;
}

.add-on {
	right: 11px;
	top: 8px;
	font-size: 15px;
	line-height: 20px;
	font-weight: $font-regular;
	color: $bunting;
}
.phone-number-wrapper {
	.react-tel-input {
		.form-control {
			width: 100%;
			border-radius: 6px;
			border: 1px solid $dark-gray;
			font-size: 15px;
			font-weight: $font-regular;
			color: $bunting;
			&:focus {
				box-shadow: none;
			}
		}
	}
}

.error-message {
	font-size: 15px;
	font-weight: $font-regular;
	line-height: 23px;
	color: $red;
}
.error-border {
	border: 0.5px solid $font-regular;
}

.user-id-input {
	width: 250px;
}
