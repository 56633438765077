@import '~assets/css/variable.scss';

.properties-table {
	width: 100%;
}

.overflow-table {
	max-height: 350px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.custom-properties-div {
	position: relative;
	width: 100%;
	height: auto;
	max-width: 528px;
	background-color: $white-color;
	border-radius: 12px;
	margin: 0 auto;
	.custom-properties-wrapper {
		padding: 0 30px 30px;
	}
	.popup-close {
		padding: 10px 10px 0 0;
		width: fit-content;
		cursor: pointer;
	}
	.custom-properties-tittle {
		font-size: 20px;
		font-weight: $font-bold;
		line-height: 23px;
		color: $yellow-sea;
	}
	.section-title {
		h6 {
			position: relative;
		}

		.spinner-wrapper {
			position: absolute;
			right: -45px;
			top: -8px;
		}
	}
}
.delete-info-popup {
	max-width: 526px;
}

.custom-prop-item {
	border: 1px solid #cecece;
	width: 100%;
	max-width: 420px;
	height: 44px;
	border-radius: 8px;
	padding-right: 20px;
	background: $bunting;
	box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
	margin: 0 auto;
	.custom-prop-tittle {
		width: 700px;
		color: $yellow-sea;
		font-size: 13px;
		line-height: 23px;
		font-weight: $font-bold;
	}
}

.custom-prop-icons {
	width: 100%;
	text-align: end;
	i {
		border-radius: 50%;
		padding: 6px;
		margin: 3px;
		color: white;
		font-size: 14px;
		cursor: pointer;
	}

	.fa-pencil,
	.fa-eye {
		background-color: $yellow-sea;

		&:hover {
			background-color: #8693ac;
		}
	}

	.fa-trash {
		background-color: $yellow-sea;

		&:hover {
			background-color: #c41c50;
		}
	}

	.fa-heart,
	.fa-heart-o {
		background-color: $primary-color;

		&:hover {
			color: $white-color;
		}
	}
}

.prop-list {
	animation: options 0.4s backwards;
	padding: 5px 0;
}

.custom-thumbnail {
	margin-right: 20px;

	img {
		width: 51px;
		height: 42px;
		border-radius: 4px;
	}
}

.properties-table,
.overflow-table,
.add-prop-main {
	scroll-behavior: smooth;
}

.close-icon {
	position: absolute;
	right: 15px;
	top: 5px;
}

.upload-attachments-wrapper {
	span {
		font-size: 14px;
	}
}

.preview-img {
	width: 50px;
	height: 20px;
	object-fit: contain;
}

.hide {
	display: none !important;
}

@media only screen and (max-width: 1366px) {
	.properties-table {
		td {
			&:nth-child(2) {
				width: 11%;
			}
		}
	}
}
