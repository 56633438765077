* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

body,
html {
	height: $full-height;
	font-family: $font;
}

.layout-container {
	width: 100%;
	max-width: 1920px;
	margin: 0 auto;
}

.tips-description,
.filter-data-container {
	&::-webkit-scrollbar {
		width: 10px;
		height: 18px;
		background-color: $white-color;
		border-radius: 7px;
	}

	&::-webkit-scrollbar-thumb {
		height: 6px;
		background-clip: padding-box;
		border-radius: 10px;
		background-color: $white-color;
	}

	&::-webkit-scrollbar-thumb {
		height: 6px;
		background-clip: padding-box;
		border-radius: 10px;
		background-color: #cecece;
	}

	&::-webkit-scrollbar-corner {
		background-color: transparent;
	}
}

.text--primary {
	color: $primary-color;
}

.height-full {
	height: $full-height;
}

a {
	&:focus {
		outline: none !important;
	}

	&:hover {
		text-decoration: none;
	}
}

.text-secondary {
	color: $primary-color !important;
}
.text-decoration-none {
	text-decoration: none;
}

.color-green {
	background-color: $secondary-color;
	border-color: $secondary-color;
	color: $white-color;
	font-weight: $font-bold;
}

.color-red {
	background-color: #d84747;
	border-color: #d84747;
	color: $white-color;
	font-weight: $font-bold;
	padding: 0px 5px;
	border: 0.5px solid $dark-gray;
	border-radius: 4px;
	font-size: 9px;
	text-align: center;
}
.color-yellow {
	background-color: $yellow-sea;
	color: $white-color;
	font-weight: $font-bold;
	padding: 0px 5px;
	border: 0.5px solid $dark-gray;
	border-radius: 4px;
	font-size: 9px;
	text-align: center;
}

.pointer {
	cursor: pointer;
}
.not-allowed {
	cursor: not-allowed;
}
/*---------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0px;
}

p {
	font-size: 14px;
	line-height: 1.7;
	margin: 0px;
}

.m--0-auto {
	margin: 0 auto !important;
}

.color-purple {
	background-color: $primary-color;
	color: $white-color;
}

input[type='checkbox'] {
	display: inline-block;
	// width: 25px;
	// height: 25px;
	padding: 6px;
	background-clip: content-box;
	border: 1.5px solid #bbbbbb;
	border-radius: 8px;
	cursor: pointer;
}

.visible {
	visibility: visible;
}

.hidden {
	visibility: hidden;
}

.logo-svg {
	.st0 {
		fill: #a0acce;
	}

	.st1 {
		fill: $primary-color;
	}
}

.object-fit-cover {
	object-fit: cover;
}

.p-b-5 {
	padding-bottom: 5px;
}

.pb-10 {
	padding-bottom: 10px;
}

.p-t-5 {
	padding-top: 5px;
}

.m-t-15 {
	margin-top: 15px;
}
.m-t-25 {
	margin-top: 25px;
}

.m-t-30 {
	margin-top: 30px;
}

.m-t-32 {
	margin-top: 32px;
}

.m-b-20 {
	margin-bottom: 20px;
}
.m-b-15 {
	margin-bottom: 15px;
}
.ml--15 {
	margin-left: 15px;
}
.ml--30 {
	margin-left: 30px;
}
.mr--5 {
	margin-right: 5px;
}
.mr--15 {
	margin-right: 15px;
}

.full-width {
	width: 100% !important;
}

.text-white {
	color: $white-color;
}

.text-black {
	color: $black-color;
}

.cursor-pointer {
	cursor: pointer;
}

.input-range__track--active {
	background: $primary-color !important;
}

.primary-btn {
	background-color: $primary-color;
	color: $white-color;
	border-color: $primary-color !important;

	&:hover {
		background-color: $white-color !important;
		color: $primary-color !important;
	}
}
.w--25 {
	width: 25%;
}
.w--33 {
	width: 33%;
}
.w--50 {
	width: 50%;
}

.flex {
	display: flex;
}

.flex--column {
	flex-direction: column;
}

.flex--column-reverse {
	flex-direction: column-reverse;
}

.flex--row {
	flex-direction: row;
}

.flex--row-reverse {
	flex-direction: row-reverse;
}

.flex--wrap {
	flex-wrap: wrap;
}
.justify__content--center {
	justify-content: center;
}
.justify__content--end {
	justify-content: flex-end;
}

.justify__content--start {
	justify-content: flex-start;
}

.justify__content--between {
	justify-content: space-between;
}

.justify__content--around {
	justify-content: space-around;
}

.justify__content--evenly {
	justify-content: space-evenly;
}

.align__items--center {
	align-items: center;
}

.align__items--end {
	align-items: flex-end;
}

.align__items--start {
	align-items: flex-start;
}

.align__items--baseline {
	align-items: baseline;
}

.align__items--stretch {
	align-items: stretch;
}

.align__content--baseline {
	align-content: baseline;
}
.text--left {
	text-align: left;
}

.text--right {
	text-align: right !important;
}

.text--center {
	text-align: center;
}

.transform--180 {
	transform: rotate(180deg);
}

.d-none {
	display: none;
}
.d-block {
	display: block;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.common-button {
	border-radius: 6px;
	border: 0.5px solid $dark-gray;
	background-color: $yellow-sea;
	padding: 9px 17px;
	font-size: 15px;
	font-weight: 700;
	line-height: 23px;
	color: $white-color;
	cursor: pointer;
	&:hover {
		background-color: $yellow-sea-hover !important;
	}
	&:disabled,
	&:disabled:hover {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

.confirm-wrapper {
	padding: 24px 0 50px;
	.confirm-container {
		display: block;
		position: relative;
		padding: 0;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		p {
			font-size: 11px;
			line-height: 16px;
			font-weight: $font-regular;
			color: $bunting;
			margin-left: 24px;
		}
	}

	/* Hide the browser's default checkbox */
	.confirm-container input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
	}

	/* Create a custom checkbox */
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 17px;
		width: 17px;
		background-color: $white-color;
		border: 0.5px solid $dark-gray;
		border-radius: 4px;
	}

	/* On mouse-over, add a grey background color */
	.confirm-container:hover input ~ .checkmark {
		background-color: $grey-4;
	}

	/* When the checkbox is checked, add a blue background */
	.confirm-container input:checked ~ .checkmark {
		background-color: $yellow-sea;
	}

	/* Create the checkmark/indicator (hidden when not checked) */
	.checkmark:after {
		content: '';
		position: absolute;
		display: none;
	}

	/* Show the checkmark when checked */
	.confirm-container input:checked ~ .checkmark:after {
		display: block;
	}

	/* Style the checkmark/indicator */
	.confirm-container .checkmark:after {
		left: 5px;
		top: 2px;
		width: 5px;
		height: 10px;
		border: solid white;
		border-width: 0 3px 3px 0;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
	}
}

.popup-background {
	position: fixed;
	width: 100%;
	height: 100vh;
	top: 0;
	z-index: 20;
	background-color: rgba(0, 0, 0, 0.25);
}
.user-register {
	top: 120px;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 2;
}
.popup-wrapper {
	// display: none;
	position: fixed;
	left: 0;
	top: 0;
	animation: popup 0.3s ease-in-out;
	z-index: 20;
}
.show-popup {
	display: flex;
}

@keyframes popup {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.close-dropdown {
	transform: rotate(-30deg);
	margin-right: 12px;
	svg {
		&:hover {
			opacity: 0.8;
		}
	}
}

.transparent-spinner {
	.body-wrapper {
		box-shadow: none !important;
		background-color: transparent !important;
	}
}

.font-size--15 {
	font-size: 15px;
}

.font-size--16 {
	font-size: 16px;
}

.small-icon {
	width: 16px;
	height: 16px;
}
