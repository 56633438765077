@import '../../assets/css/variable.scss';

.home-header {
	padding: 14px 0;
	.home-icon {
		width: 243px;
		height: 60px;
		fill: $bunting;
	}
	.navlink-data {
		padding: 0 10px;
		font-size: 14px;
		font-weight: $font-bold;
		line-height: 30px;
		color: $bunting;
		text-decoration: none;
		&::after {
			content: '';
			width: 0px;
			height: 2px;
			display: block;
			background: $bunting;
			transition: 300ms;
		}
		&:hover::after {
			width: 100%;
		}
	}
	.active-page {
		color: $tangerine;
	}
	.profile-icon {
		display: flex;
		align-items: center;
		padding: 0 0 0 37px;
		position: relative;
		span {
			cursor: pointer;
			font-size: 14px;
			line-height: 30px;
			font-weight: $font-bold;
			margin-right: 5px;
		}
	}
	.registration-nav-bar {
		padding: 0 10px;
		span {
			&::after {
				content: '';
				width: 100%;
				height: 2px;
				display: block;
				background: $bunting;
				transition: 300ms;
			}
			&:hover::after {
				animation: underLine 300ms ease-out;
			}
			@keyframes underLine {
				from {
					width: 0px;
				}
				to {
					width: 100%;
				}
			}
		}
	}

	.profile-dropdown {
		background-color: #fff;
		border-radius: 12px;
		box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.15);
		cursor: pointer;
		padding: 17px 12px 17px;
		position: absolute;
		left: 10px;
		top: 61px;
		width: 158px;
		z-index: 10002;
		li {
			padding: 4px 10px;
			font-size: 12px;
			font-weight: $font-regular;
			line-height: 23px;
			color: $bunting;
			text-decoration: none;

			&:hover {
				background-color: $bg-white;
			}
		}
	}
}
.profile-details {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.25);
	border: 1px solid grey;
	height: 100vh;
	width: 100vw;
	left: 0;
	margin: 0;
	opacity: 1;
	padding: 80px 50px 50px;
	position: fixed;
	top: 0;
	z-index: 20;
	transition: all 0.4s ease;
}

.main-footer {
	padding: 40px 40px;
	background-color: $bunting;
	.footer-logo-div {
		width: 30%;
		.footer-logo-wrapper {
			width: 243px;
			height: 62px;
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	.footer-link-wrapper {
		width: 32%;
		max-width: 800px;
		.footer-link {
			.footer-link-title {
				font-size: 15px;
				font-weight: $font-bold;
				line-height: 30px;
				color: $white-color;
			}
			.footer-link-text {
				p {
					font-size: 15px;
					font-weight: $font-light;
					line-height: 30px;
					color: $white-color;
					width: fit-content;
					&::after {
						content: '';
						width: 0px;
						height: 1px;
						display: block;
						background: $white-color;
						transition: 300ms;
					}
					&:hover::after {
						width: 100%;
					}
				}
			}
		}
	}
	.home-icon {
		width: 243px;
		height: 60px;
		fill: $white-color;
	}
}
