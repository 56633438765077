@import '../../assets/css/variable.scss';

.user-profile-wrapper {
	.profile-detail-wrapper {
		.profile-detail-div {
			.section-title {
				font-size: 36px;
				line-height: 35px;
				color: $bunting;
				font-weight: $font-regular;
				margin: 65px 0 20px;
			}
			.section-text {
				font-size: 14px;
				line-height: 40px;
				color: $bunting;
				font-weight: $font-regular;
			}
			.user-detail-wrapper {
				background-color: $light-red;
				padding: 20px 0;
				.user-detail-div {
					width: 100%;
					max-width: 647px;
					margin: 0 auto;
					.confirm-section {
						margin-bottom: 92px;
						.user-detail-confirm-wrapper {
							padding: 24px 0 20px;
							.confirm-container {
								p {
									font-size: 15px;
									line-height: 23px;
								}
							}
						}
						.cancel-changes-btn {
							font-size: 15px;
							font-weight: $font-regular;
							color: $bunting;
							line-height: 40px;
						}
					}
					h6 {
						font-size: 11px;
						line-height: 40px;
						font-weight: $font-regular;
						color: $black-color;
					}
					.user-detail-tittle {
						font-size: 15px;
						font-weight: $font-bold;
						margin-bottom: 32px;
					}
					.user-roll-wrapper {
						.user-roll-div {
							.radio-button-wrapper {
								padding: 0;
							}
							.checkmark {
								top: -20px;
								left: 40%;
							}
						}
					}
				}
				.delete-btn {
					font-size: 15px;
					line-height: 40px;
					font-weight: $font-regular;
					color: $gray;
					margin: 0 0 20px;
				}
			}
		}
	}
}
.save-button {
	width: 181px;
}
